// import Tool
import Tooltip from '@mui/material/Tooltip';
export default function Light(props) {
  const {
    size = '24',
    blink = false,
    lightOn = true,
    description = '',
    color,
    divProps,
  } = props;

  const blinkAnimation = blink
    ? {
        animation: 'blinker 1s ease-in-out infinite',
        WebkitAnimation: 'blinker 1s ease-in-out infinite',
        MozAnimation: 'blinker 1s ease-in-out infinite',
        msAnimation: 'blinker 1s ease-in-out infinite',
        OAnimation: 'blinker 1s ease-in-out infinite',
      }
    : {};
  return (
    <>
      <style>
        {`
        @keyframes blinker {
            0% {
                opacity: 1;
              }
            
              50% {
                opacity: 0.5;
                boxShadow: inset 0 0 ${Math.round(size / 2)}px ${Math.round(
          size / 4
        )}px ${color}, 0 0 0 0
        )}px ${color};
              }
            
              100% {
                opacity: 1;
        }
      `}
      </style>
      <Tooltip
        title={description}
        placement="top"
        sx={{
          visibility:
            description && description.length > 0 ? 'visible' : 'hidden',
        }}
      >
        {lightOn ? (
          <div
            style={{
              width: `${size}px`,
              height: `${size}px`,
              backgroundColor: 'white',
              borderRadius: '50%',
              boxShadow: `inset 0 0 ${Math.round(size / 2)}px ${Math.round(
                size / 4
              )}px ${color}, 0 0 ${Math.round(size) / 2}px 1px ${color},
              0 0 8px 1px #bfbfbfc9`,
              ...blinkAnimation,
              ...divProps,
            }}
          />
        ) : (
          <div
            style={{
              width: `${size}px`,
              height: `${size}px`,
              backgroundColor: 'white',
              borderRadius: '50%',
              boxShadow: `inset 0 0 ${Math.round(size / 2)}px ${Math.round(
                size / 4
              )}px #ababab`,

              ...divProps,
            }}
          />
        )}
      </Tooltip>
    </>
  );
}
