import { useContext, useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import BoltIcon from '@mui/icons-material/Bolt';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SpeedIcon from '@mui/icons-material/Speed';
import { DashboardContext } from '@/store/dashboard-context';
import { AlarmContext } from '@/store/alarm-context';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { timestampToLocalDateTimeShortest } from '@/utils/dateUtil';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import WaterIcon from '@mui/icons-material/Water';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import CableIcon from '@mui/icons-material/Cable';
import AirIcon from '@mui/icons-material/Air';
import EvStationIcon from '@mui/icons-material/EvStation';
import TimerIcon from '@mui/icons-material/Timer';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import CardBox from './CardBox';
import useIsMobile from '@/hooks/useIsMobile';
import SelectionMenuWrapper from '../utils/SelectionMenuWrapper';
import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import { conversion } from '@/utils/formatFunctions';
import RenderLoading from './utils/RenderLoading';
export default function UsageCard(props) {
  const { statisticsData, editMode, options, changeItem, dataLoading } =
    useContext(DashboardContext);
  const { alarmData } = useContext(AlarmContext);
  const [highlight, setHighlight] = useState({});
  const [lineHeight, setLineHeight] = useState(20);
  const titleRef = useRef();
  const optionsValueCard = { ...options };
  for (const key in optionsValueCard) {
    if (!(key.endsWith('Etotal') || key.endsWith('H2total'))) {
      delete optionsValueCard[key];
    }
  }

  const { item } = props;
  const { content, dataId } = item;
  const isMobile = useIsMobile('sm');

  useEffect(() => {
    //check
    if (
      !objEmptyOrUndefined(alarmData) &&
      alarmData.category === 'cardNonEmptyError'
    ) {
      //check if we are mentioned in the alarm
      const foundSelf = alarmData.data.nonEmpty.filter(
        (card) => card.position[3] == item.position[3]
      );
      if (foundSelf.length > 0) {
        setHighlight({
          '@keyframes pulse': {
            '0%, 100%': {
              backgroundColor: 'primary.light',
            },
            '50%': {
              backgroundColor: 'alarm.danger',
            },
          },
          animation: 'pulse 1s infinite',
        });
        setTimeout(() => {
          setHighlight({});
        }, alarmData.duration);
      }
    }
  }, [alarmData]);

  const iconLib = {
    temperature: (
      <DeviceThermostatIcon
        fontSize="medium"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    battery: (
      <Battery5BarIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    power: <BoltIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />,
    error: (
      <ReportProblemIcon
        fontSize="medium"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    pressure: (
      <SpeedIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    current: (
      <WaterIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    electricMeter: (
      <ElectricMeterIcon
        fontSize="medium"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    gasMeter: (
      <GasMeterIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    voltage: (
      <CableIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    ventilator: (
      <AirIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    usage: (
      <EvStationIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />
    ),
    timer: <TimerIcon fontSize="medium" style={{ verticalAlign: 'middle' }} />,
    number: (
      <FormatListNumberedIcon
        fontSize="medium"
        style={{ verticalAlign: 'middle' }}
      />
    ),
  };

  useEffect(() => {
    titleRef.current;
    if (titleRef.current) {
      const lineHeightNew = window
        .getComputedStyle(titleRef.current, null)
        .getPropertyValue('line-height');
      setLineHeight(parseFloat(lineHeightNew.replace('px', '')));
    }

    // const lineHeight = window
    //   .getComputedStyle(titleRef.current, null)
    //   .getPropertyValue("line-height");
  });
  const createValue = () => {
    let val = 0;
    if (statisticsData.length == 0) return;
    statisticsData.forEach((stat) => {
      if (objEmptyOrUndefined(stat)) return;
      if (!('statistics' in stat)) return;
      if (!(dataId in stat.statistics)) return;
      val += stat.statistics[dataId].range;
    });

    if (dataId in options) {
      return conversion(
        val,
        options[dataId].conversion,
        options[dataId].decimals
      );
    } else {
      return val;
    }
  };

  const parseUnit = () => {
    let unit = '';

    if (
      !objEmptyOrUndefined(options) &&
      dataId in options &&
      options[dataId].unit
    )
      unit = options[dataId].unit;

    if (unit == '°') return '°';
    else {
      return ` ${unit}`;
    }
  };

  const renderResult = () => {
    return (
      <>
        <Grid container alignContent={'center'}>
          <Grid item xs={6}>
            <Typography
              variant={isMobile ? 'body2' : 'subtitle1'}
              fontSize={isMobile ? '1.0rem' : '1.3rem'}
              sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
              color="primary.dark"
            >
              {createValue()}
              {parseUnit()}
            </Typography>
          </Grid>
          {!isMobile && item.size[0] > 3 && (
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
                color="primary.dark"
              >
                {!objEmptyOrUndefined(options) && iconLib[options[dataId].icon]}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography variant="caption">
          {statisticsData.length > 0 &&
            'statistics' in statisticsData[statisticsData.length - 1] &&
            dataId in statisticsData[statisticsData.length - 1].statistics &&
            ('startDate' in statisticsData[statisticsData.length - 1]
              ? `${timestampToLocalDateTimeShortest(
                  statisticsData[statisticsData.length - 1].startDate
                )} - `
              : '')}
          {statisticsData.length > 0 &&
            'statistics' in statisticsData[0] &&
            dataId in statisticsData[0].statistics &&
            ('endDate' in statisticsData[0]
              ? `${timestampToLocalDateTimeShortest(statisticsData[0].endDate)}`
              : '')}
        </Typography>
      </>
    );
  };

  return (
    <CardBox height={1}>
      <Card
        sx={{
          paddingTop: editMode ? '1.2rem' : '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.light',
          position: 'relative',
          ...highlight,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-end',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
              <SelectionMenuWrapper
                buttonProps={{ size: 'small', sx: { margin: 0, padding: 0 } }}
                value={dataId}
                options={optionsValueCard}
                onChange={changeItem}
                additionalArgsOnChange={[item.position[3]]}
                labelIdentifier="name"
                menuId={`valueMenu-${item.position[3].toString()}`}
                autocompleteProps={{ sx: { width: '15rem' } }}
                onHover={false}
              >
                <MoreVertIcon fontSize="small" sx={{ margin: 0, padding: 0 }} />
              </SelectionMenuWrapper>
            </>
          )}
        </Box>
        <CardContent sx={{ padding: '0.5rem' }}>
          {dataId == null || dataId === '' ? (
            <>
              <Box sx={{ minHeight: `${lineHeight * 2}px` }}>
                <Typography
                  variant={isMobile ? 'caption' : 'body2'}
                  color="primary.dark"
                >
                  Please define parameter to get total session data from
                </Typography>
              </Box>
              <Typography
                variant={isMobile ? 'body2' : 'subtitle1'}
                fontSize={isMobile ? '1.0rem' : '1.3rem'}
                sx={{ fontWeight: 'bold' }}
                color="primary.dark"
              >
                Usage card
              </Typography>
            </>
          ) : (
            <>
              <Box sx={{ minHeight: `${lineHeight * 2}px` }}>
                <Typography
                  variant={isMobile ? 'caption' : 'body2'}
                  color="primary.dark"
                  ref={titleRef}
                >
                  {!objEmptyOrUndefined(options) &&
                    dataId in options &&
                    options[dataId].name &&
                    `${options[dataId].name}`}
                </Typography>
              </Box>

              {dataLoading.statistics ? <RenderLoading /> : renderResult()}
            </>
          )}
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const usageCardObj = (position, size) => {
  const obj = {
    type: 'UsageCard',
    typeLabel: `Usage Card ${size[0] / 3}x${size[1]}`,
    content: {},
    dataId: '',
    size,
    position,
  };
  return obj;
};
