import { createContext, useEffect, useState, useContext } from 'react';
import { copyObj, objEmptyOrUndefined } from '@/utils/arrayFunctions';
import fetchData from '@/utils/fetchData';
import { emptyCardObj } from '@/components/Dashboard/EmptyCard';

import { AuthContext } from './auth-context';
import { AlarmContext } from './alarm-context';

import { getCard } from '@/components/Dashboard/utils/cardLists';
export const DashboardContext = createContext({
  channelData: {},
  alarmData: {},
  valueData: {},
  editMode: false,
  options: {},
  alarmOptions: {},
  items: {},
  refreshData: 0,
  reloadDashboardList: 0,
  statisticsData: [],
  isLive: false,
  activeSessionAvailable: false,
  unitIsOnline: false,
  sessions: [],
  selectedSessions: [],
  startDate: null,
  endDate: null,
  breakSession: false,
  statusCodes: {},
  unitData: {},
  setUnitData: () => {},
  setSessions: () => {},
  setSelectedSessions: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  setBreakSession: () => {},
  setUnitIsOnline: () => {},
  setActiveSessionAvailable: () => {},
  setIsLive: () => {},
  setStatisticsData: () => {},
  setReloadDashboardList: () => {},
  setRefreshData: () => {},
  getAlarmOptions: () => {},
  setAlarmData: () => {},
  setValueData: () => {},
  setSessionData: () => {},
  resetData: () => {},
  setEditMode: () => {},
  changeItem: () => {},
  changeAlarm: () => {},
  getItem: () => {},
  setItems: () => {},
  changeCardType: () => {},
  insertEmptyCard: () => {},
  removeCard: () => {},
  moveCard: () => {},
  updateChannelData: () => {},
  updateValueData: () => {},
  dataLoading: {
    value: false,
    alarm: false,
    session: false,
    unit: false,
    statistics: false,
  },
  setDataLoading: () => {},
});

const DashboardContextProvider = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { setAlarmData: setAlarmMessage } = useContext(AlarmContext);
  const [isLive, setIsLive] = useState(false);
  const [unitIsOnline, setUnitIsOnline] = useState(false);
  const [activeSessionAvailable, setActiveSessionAvailable] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [alarmData, setAlarmData] = useState({});
  const [valueData, setValueData] = useState({});
  const [sessionData, setSessionData] = useState([]);
  const [statisticsData, setStatisticsData] = useState([]);
  const [editMode, setEditModeInternal] = useState(false);
  const [options, setOptions] = useState({});
  const [alarmOptions, setAlarmOptions] = useState({});
  const [items, setItems] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [reloadDashboardList, setReloadDashboardList] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [breakSession, setBreakSession] = useState(false);
  const [statusCodes, setStatusCodes] = useState({});
  const [unitData, setUnitData] = useState({});
  const [dataLoading, setDataLoading] = useState({
    value: false,
    alarm: false,
    session: false,
    unit: false,
    statistics: false,
  });

  //this function is used to change the parameter that is rendered on the dashboard
  const changeItem = (newItemDataId, position) => {
    console.log(
      `trying to change position, newItemDataId: ${newItemDataId}`,
      position
    );
    setItems((prevItems) => {
      if (!newItemDataId in options) return prevItems;
      const newItems = copyObj(prevItems);
      if (newItemDataId == null) {
        if (position.length === 1) newItems[position[0]].dataId = '';
        else if (position.length === 2) {
          newItems[position[0]].content[position[1]].dataId = '';
        }
        return newItems;
      }
      if (position.length === 1) {
        newItems[position[0]].dataId = newItemDataId;

        return newItems;
      } else if (position.length === 2) {
        newItems[position[0]].content[position[1]].dataId = newItemDataId;

        return newItems;
      }
      return newItems;
    });
  };

  const changeAlarm = (newAlarmId, position, alarmData) => {
    if (!(newAlarmId in alarmData)) return;
    const newItemDataId = alarmData[newAlarmId].channelName;
    setItems((prevItems) => {
      if (!newItemDataId in options) return prevItems;
      const newItems = copyObj(prevItems);
      if (newItemDataId == null) {
        if (position.length === 1) {
          newItems[position[0]].dataId = '';
          newItems[position[0]].alarmTemplateId = '';
        } else if (position.length === 2) {
          newItems[position[0]].content[position[1]].dataId = '';
          newItems[position[0]].content[position[1]].alarmTemplateId = '';
        }
        return newItems;
      }
      if (position.length === 1) {
        newItems[position[0]].dataId = newItemDataId;
        newItems[position[0]].alarmTemplateId = newAlarmId;
        return newItems;
      } else if (position.length === 2) {
        newItems[position[0]].content[position[1]].dataId = newItemDataId;
        newItems[position[0]].content[position[1]].alarmTemplateId = newAlarmId;

        return newItems;
      }
      return newItems;
    });
  };

  const getItem = (position) => {
    if (position.length === 1) {
      if (items.length <= position[0]) return null;
      return items[position[0]];
    } else if (position.length === 2) {
      if (
        items.length <= position[0] ||
        items[position[0]].content.length <= position[1]
      )
        return null;
      return items[position[0]].content[position[1]];
    }
  };
  const getStatusErrorDescription = async () => {
    const [data, success, errorMessage] = await fetchData(
      '/settings/statusCodes'
    );
    if (success) {
      setStatusCodes(data);
    }
  };

  const getOptions = async () => {
    const [data, success, errorMessage] = await fetchData('/channels/options');
    if (success) {
      setOptions(data);
    }
  };
  useEffect(() => {
    if (!isLoggedIn) return;
    const getData = async () => {
      await getOptions();
      await getStatusErrorDescription();
    };

    getData();
  }, [isLoggedIn]);

  const getAlarmOptions = async () => {
    const [docs, success, errorMessage] = await fetchData(
      '/alarms?fields=name,description,channelName&sort=name',
      'docs'
    );
    if (success) {
      const docsObj = {};
      docs.forEach((doc) => (docsObj[doc._id] = doc));
      setAlarmOptions(docsObj);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    getAlarmOptions();
  }, [isLoggedIn]);

  const setEditMode = (editMode) => {
    setEditModeInternal(editMode);

    if (editMode) {
      //add 12 trailing empty cards
      //these can be used to define new cards
      setItems((prevItems) => {
        const newItems = copyObj(prevItems);
        const startLength = newItems.length;
        //check if we have 2 columns data
        const twoColumnData =
          newItems.length > 0 && '2' in newItems[0].position ? true : false;
        const oneColumnData =
          newItems.length > 0 && '1' in newItems[0].position ? true : false;

        for (let i = 0; i < 12; i++) {
          const localPos = [startLength + i];
          const totalPos = { 3: [...localPos] };
          if (twoColumnData) totalPos[2] = [...localPos];
          if (oneColumnData) totalPos[1] = [...localPos];
          newItems.push(emptyCardObj(totalPos, [12, 1]));
        }
        return newItems;
      });
    } else {
      //remove trailing empty cards
      setItems((prevItems) => {
        const newItems = copyObj(prevItems);
        while (
          newItems.length > 0 &&
          newItems[newItems.length - 1].type === 'EmptyCard'
        ) {
          newItems.pop();
        }
        return newItems;
      });
    }
  };

  const checkSpace = (nestedCard) => {
    let totalSpace = 0;
    nestedCard.content.forEach((card) => {
      totalSpace += card.size[0];
    });
    return totalSpace;
  };

  const changeCardType = (type, objInput, position) => {
    const newObj = getCard(type[0], objInput[0]);
    for (let i = 1; i < type.length; i++) {
      newObj.content.push(getCard(type[i], objInput[i]));
    }
    setItems((prevItems) => {
      const newItems = copyObj(prevItems);

      if (position[3].length === 1) newItems[position[3][0]] = newObj;
      else if (position[3].length === 2) {
        newItems[position[3][0]].content[position[3][1]] = newObj;
        //check if we do not exceed the space
        let remainingTrials = 13;
        while (
          checkSpace(newItems[position[3][0]]) > 12 &&
          remainingTrials > 0
        ) {
          //we must remove some empty cards to make space. Starting with the last one
          for (
            let i = newItems[position[3][0]].content.length - 1;
            i >= 0;
            i -= 1
          ) {
            if (newItems[position[3][0]].content[i].type === 'EmptyCard') {
              newItems[position[3][0]].content.splice(i, 1);
              break;
            }
          }
          remainingTrials -= 1;
        }
        //check if the space is now exactly 12
        if (checkSpace(newItems[position[3][0]]) !== 12) {
          //we must fill the remaining space with an empty card
          const remainingSpace = 12 - checkSpace(newItems[position[3][0]]);
          //add empty card with remaining space
          const obj = emptyCardObj(
            {
              3: [position[3][0], newItems[position[3][0]].content.length],
            },
            [remainingSpace, 1]
          );
          newItems[position[3][0]].content.push(obj);
        }
      }

      return newItems;
    });
  };

  const removeCard = (position) => {
    if (position[3] == null) return;
    //removing a card happens in two steps
    //1. remove the card
    //2. update the remaining cards positioning
    let require_empty_card = false;
    setItems((prevItems) => {
      const newItems = copyObj(prevItems);

      //first get the position for 2 and 1 columns if available
      let referencePosition2 = 2 in position ? position[2][0] : -1;
      let referencePosition1 = 1 in position ? position[1][0] : -1;
      if (position[3].length === 1) {
        newItems.splice(position[3][0], 1);
      } else if (position[3].length === 2) {
        //case for nested component
        //check size of original card
        const origSize = newItems[position[3][0]].content[position[3][1]].size;
        //remove old card
        newItems[position[3][0]].content.splice(position[3][1], 1);
        //replace with empty card
        const obj = emptyCardObj(
          {
            3: [position[3][0], position[3][1]],
          },
          origSize
        );
        newItems[position[3][0]].content.splice(position[3][1], 0, obj);

        //check if nested card is empty, if so we need to transform it into an empty card instead
        if (newItems[position[3][0]].content.length === 0)
          require_empty_card = true;
        if (
          newItems[position[3][0]].content.filter(
            (card) => card.type != 'EmptyCard'
          ).length === 0
        )
          require_empty_card = true;
      }

      //step 2 change numbering for all cards
      //3 columns this is easy to deduce
      for (let i = position[3][0]; i < newItems.length; i++) {
        if (newItems[i].type === 'NestedCard') {
          for (let j = 0; j < newItems[i].content.length; j++) {
            newItems[i].content[j].position[3] = [i, j];
          }
        }
        newItems[i].position[3] = [i];
      }
      //if we have the main positioning the 2 column and 1 column positioning is also impacted
      if (referencePosition2 !== -1 && position[3].length === 1) {
        for (let i = 0; i < newItems.length; i++) {
          //check if not self or before self
          if (newItems[i].position[2][0] >= referencePosition2) {
            newItems[i].position[2][0] -= 1;
          }
        }
      }
      //if we have the main positioning the 2 column and 1 column positioning is also impacted
      if (referencePosition1 !== -1 && position[3].length === 1) {
        for (let i = 0; i < newItems.length; i++) {
          //check if not self or before self
          if (newItems[i].position[1][0] >= referencePosition1) {
            newItems[i].position[1][0] -= 1;
          }
        }
      }
      return newItems;
    });

    if (require_empty_card) {
      const parentCard = getItem([position[3][0]]);
      changeCardType(
        ['EmptyCard'],
        [[parentCard.position, [12, 1]]],
        parentCard.position
      );
    }
  };

  const moveCard = (position, left, columns) => {
    setItems((prevItems) => {
      const newItems = copyObj(prevItems);

      if (columns === 3 || position[3].length === 2) {
        const to = [...position[3]];
        const add = left ? -1 : 1;
        if (position[3].length === 2) {
          to[1] += add;
        } else {
          to[0] += add;
        }
        const from = [...position[3]];

        //check if to card exists
        let toCard = getItem(to);
        let fromCard = getItem(from);
        if (toCard == null) {
          setAlarmMessage({
            msg: 'Cannot move this card in this direction',
            type: 'error',
            category: 'cardMoveError',
            duration: 8000,
          });
          return prevItems;
        }
        // first swap the position properties
        const fromPosition = { ...fromCard.position };
        const toPosition = { ...toCard.position };
        toCard.position = { ...fromPosition };
        fromCard.position = { ...toPosition };
        if (toCard.type === 'NestedCard') {
          for (let i = 0; i < toCard.content.length; i++) {
            toCard.content[i].position[3] = [
              toCard.position[3][0],
              toCard.content[i].position[3][1],
            ];
          }
        }
        if (fromCard.type === 'NestedCard') {
          for (let i = 0; i < fromCard.content.length; i++) {
            fromCard.content[i].position[3] = [
              fromCard.position[3][0],
              fromCard.content[i].position[3][1],
            ];
          }
        }

        if (from.length === 1) {
          newItems.splice(from[0], 1, toCard);
          newItems.splice(to[0], 1, fromCard);
        } else if (from.length === 2) {
          newItems[from[0]].content.splice(from[1], 1, toCard);
          newItems[to[0]].content.splice(to[1], 1, fromCard);
        }
        return newItems;
      } else {
        //first check if we already have column definitions
        if (!(columns in newItems[0].position)) {
          //we need to initialize this column definition
          for (let i = 0; i < newItems.length; i++) {
            newItems[i].position[columns] = [i];
          }
        }
        const to = [...newItems[position[3][0]].position[columns]];
        const add = left ? -1 : 1;
        to[0] += add;
        //we need to find the from and to cards
        const fromIndex = position[3][0];
        const from = newItems[position[3][0]].position[columns][0] + 0;
        let toCard;
        let toIndex;
        for (let i = 0; i < newItems.length; i++) {
          if (newItems[i].position[columns][0] === to[0]) {
            toCard = { ...newItems[i] };
            toIndex = i;
            break;
          }
        }
        //check if we have bot cards
        if (toCard == null) {
          setAlarmMessage({
            msg: 'Cannot move this card in this direction',
            type: 'error',
            category: 'cardMoveError',
            duration: 8000,
          });
          return prevItems;
        }
        //swap the position properties
        newItems[fromIndex].position[columns][0] = to[0];
        newItems[toIndex].position[columns][0] = from;
        return newItems;
      }
    });
  };

  const insertEmptyCard = (position, size, after) => {
    //first determine the new position
    const newPosition = copyObj(position);
    const add = after ? 1 : 0;
    if (position[3].length === 1) {
      for (const key in position) {
        newPosition[key][0] += add;
      }
    } else {
      newPosition[3][1] += add;
    }

    //get the card
    const obj = emptyCardObj(newPosition, size);
    //insertion at a position happens in two steps
    //1. add an empty card
    //2. update the cards
    setItems((prevItems) => {
      //step 1
      const newItems = copyObj(prevItems);

      if (newPosition[3].length === 1) {
        newItems.splice(newPosition[3][0], 0, obj);
      } else if (newPosition[3].length === 2) {
        if (newItems[newPosition[3][0]].type !== 'NestedCard') return newItems;
        //check if we actually have space
        //sum values of content array
        let sum = 0;
        for (let i = 0; i < newItems[newPosition[3][0]].content.length; i++) {
          sum += newItems[newPosition[3][0]].content[i].size[0];
        }
        if (sum + size[0] > 12) {
          return prevItems;
        }
        newItems[newPosition[3][0]].content.splice(newPosition[3][1], 0, obj);
      }
      //step 2 change numbering for all cards
      //3 columns this is easy to deduce
      for (let i = newPosition[3][0]; i < newItems.length; i++) {
        if (newItems[i].type === 'NestedCard') {
          for (let j = 0; j < newItems[i].content.length; j++) {
            newItems[i].content[j].position[3] = [i, j];
          }
        }
        newItems[i].position[3] = [i];
      }
      //if we have the main positioning the 2 column and 1 column positioning is also impacted
      if (position[3].length === 1 && 2 in newItems[0].position) {
        //take position from previous card
        const referencePosition = position[2][0] + add;
        for (let i = 0; i < newItems.length; i++) {
          //check if not self or before self
          if (
            newItems[i].position[2][0] >= referencePosition &&
            i !== newPosition[3][0]
          ) {
            newItems[i].position[2][0] += 1;
          }
        }
      }
      //if we have the main positioning the 2 column and 1 column positioning is also impacted
      if (position.length === 1 && 1 in newItems[0].position) {
        //take position from previous card
        const referencePosition = position[1][0] + add;

        for (let i = 0; i < newItems.length; i++) {
          //check if not self or before self
          if (
            newItems[i].position[1][0] >= referencePosition &&
            i !== newPosition[3][0]
          ) {
            newItems[i].position[1][0] += 1;
          }
        }
      }
      return newItems;
    });
  };

  useEffect(() => {
    //get the channels
    if (
      sessionData.length > 0 &&
      !objEmptyOrUndefined(sessionData[0].channels)
    ) {
      const channels = Object.keys(sessionData[0].channels);
      const data = {};
      sessionData.forEach((session) => {
        channels.forEach((channel) => {
          if (!data[channel]) {
            data[channel] = { data: [], time: [] };
          }
          if (channel in session.channels) {
            const addData = [...session.channels[channel].data];

            //add data
            data[channel].data.unshift(...addData);
            let timeAdd = [];
            //add time
            if ('time' in session.channels[channel]) {
              timeAdd = [...session.channels[channel].time];
            } else {
              timeAdd = [...session.time];
            }
            data[channel].time.unshift(...timeAdd);
          }
        });
      });

      setChannelData(data);
    }
  }, [sessionData]);

  const updateChannelData = (data) => {
    setChannelData((prev) => {
      const newData = copyObj(prev);

      for (const channel in newData) {
        if (channel in data) {
          newData[channel].data.push(data[channel]);
          newData[channel].time.push(data.time);
        }
      }
      return newData;
    });
  };

  const updateValueData = (data) => {
    setValueData((prev) => {
      const newData = copyObj(prev);

      for (const channel in newData) {
        if (channel in data) {
          if (channel === 'Station--pos') {
            newData[channel] = {
              coordinates: [data[channel].lng, data[channel].lat],
            };
          } else {
            newData[channel] = data[channel];
          }
        }
      }
      return newData;
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      resetData();
    }
  }, [isLoggedIn]);
  const resetData = () => {
    setChannelData({});
    setAlarmData({});
    setValueData({});
    setSessionData([]);
  };

  return (
    <DashboardContext.Provider
      value={{
        channelData,
        alarmData,
        valueData,
        editMode,
        options,
        alarmOptions,
        items,
        refreshData,
        reloadDashboardList,
        statisticsData,
        isLive,
        unitIsOnline,
        activeSessionAvailable,
        sessions,
        selectedSessions,
        startDate,
        endDate,
        breakSession,
        statusCodes,
        unitData,
        setUnitData,
        setSessions,
        setSelectedSessions,
        setStartDate,
        setEndDate,
        setBreakSession,
        setActiveSessionAvailable,
        setUnitIsOnline,
        setIsLive,
        setStatisticsData,
        setReloadDashboardList,
        setRefreshData,
        getAlarmOptions,
        setAlarmData,
        setValueData,
        setSessionData,
        resetData,
        setEditMode,
        getItem,
        changeItem,
        changeAlarm,
        setItems,
        changeCardType,
        insertEmptyCard,
        removeCard,
        moveCard,
        updateChannelData,
        updateValueData,
        dataLoading,
        setDataLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
