import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import ModalCloseButton from './utils/ModalCloseButton';
import { conversion } from '@/utils/formatFunctions';
import { interpolate } from '@/utils/arrayFunctions';

export default function FuelCalculatorModal(props) {
  const { setMass: setMassParent, openModal, setOpenModal } = props;
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [volume, setVolume] = useState(0);
  const [pressure, setPressure] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [mass, setMass] = useState(0);
  const [applyCorrection, setApplyCorrection] = useState(true);

  //Avogadro's constant
  const R = 8.31446261815324;
  //Molar mass of hydrogen
  const M = 2.01568e-3;

  const handleClose = () => {
    setOpenModal(false);
  };
  const theme = useTheme();
  //show alert and close alert after timeout
  const handleAlert = (alertObj, duration) => {
    setAlert(alertObj);
    setTimeout(() => {
      setAlert({ open: false, message: '', severity: 'success' });
    }, duration * 1000);
  };
  function isNumeric(str) {
    try {
      if (isNaN(Number(str))) return false;
    } catch (e) {
      return false;
    }
    return true;
  }
  useEffect(() => {
    if (!isNumeric(volume) || !isNumeric(pressure) || !isNumeric(temperature))
      return setMass(0);
    if (
      Number(volume) <= 0 ||
      Number(pressure) <= (applyCorrection ? 20 : 0) ||
      Number(temperature) + 273.15 <= 0
    )
      return setMass(0);
    //get compressibility factor (to correct ideal gas law)
    const Z = getCompressibility(Number(pressure), Number(temperature));
    console.log('Z', Z);
    const mass =
      (Number(volume) * 1e-3 * Number(pressure) * 1e5) /
      ((R / M) * (Number(temperature) + 273.15) * Z);

    //we cannot use mass below 20 bar so do same calculation fo rthis
    let massDead = 0;
    if (applyCorrection) {
      const Zdead = getCompressibility(20, Number(temperature));
      massDead =
        (Number(volume) * 1e-3 * 20 * 1e5) /
        ((R / M) * (Number(temperature) + 273.15) * Zdead);
    }
    setMass(mass - massDead || 0);
  }, [volume, pressure, temperature, applyCorrection]);

  // handle change of form for numbers
  const updateValue = (e, type) => {
    const setter = (val) => {
      switch (type) {
        case 'volume':
          setVolume(val);
          break;
        case 'pressure':
          setPressure(val);
          break;
        case 'temperature':
          setTemperature(val);
          break;
        default:
          break;
      }
    };

    let value = e.target.value;
    if (value === '') {
      setter('');
    }

    if (!isNumeric(value)) return;
    try {
      value = parseFloat(value);
    } catch (error) {
      return;
    }
    //check if nan
    if (isNaN(value)) return;
    // set original value as this allows for poitns
    setter(e.target.value);
  };

  //handle insert
  const handleInsert = () => {
    if (
      volume * 1 <= 0 ||
      pressure * 1 <= (applyCorrection ? 20 : 0) ||
      temperature * 1 + 273.15 <= 0 ||
      !isNumeric(mass)
    ) {
      const alertObj = {
        open: true,
        message: 'All fields must contain valid values',
        severity: 'error',
      };

      handleAlert(alertObj, 8);
      return;
    }
    setMassParent(mass);
    handleClose();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflowY: 'scroll',
            maxHeight: '90%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '40rem' },
            p: { xs: '2rem', sm: '10rem' },
            bgcolor: 'background.paper',
          }}
        >
          <ModalCloseButton handleClose={handleClose} />
          <FormGroup>
            <TextField
              id="outlined-basic"
              label="Volume [liter]"
              variant="outlined"
              sx={{ mt: 1 }}
              value={volume}
              onChange={(e) => updateValue(e, 'volume')}
              error={volume * 1 <= 0}
              helperText={
                volume * 1 <= 0 ? 'Please a volume greater than 0' : ''
              }
            />
            <TextField
              id="outlined-basic"
              label="Pressure [bar]"
              variant="outlined"
              value={pressure}
              sx={{ mt: 1 }}
              onChange={(e) => updateValue(e, 'pressure')}
              error={pressure * 1 <= (applyCorrection ? 20 : 0)}
              helperText={
                pressure * 1 <= (applyCorrection ? 20 : 0)
                  ? `Please a pressure greater than ${
                      applyCorrection ? 20 : 0
                    } bar`
                  : applyCorrection
                  ? '*Note that 20 bar will be automatically deducted in this calculation to correct for minimum pressure requirement.'
                  : ''
              }
            />
            <TextField
              id="outlined-basic"
              label="Temperature [°C]"
              variant="outlined"
              value={temperature}
              sx={{ mt: 1 }}
              onChange={(e) => updateValue(e, 'temperature')}
              error={temperature * 1 + 273.15 <= 0}
              helperText={
                temperature * 1 + 273.15 <= 0
                  ? 'Please a temperature greater than 0 Kelvin'
                  : ''
              }
            />
            <TextField
              id="outlined-basic"
              label="Mass [kg]"
              variant="outlined"
              sx={{ mt: 1 }}
              value={conversion(mass, null, 1)}
              error={
                pressure * 1 <= (applyCorrection ? 20 : 0) ||
                volume * 1 <= 0 ||
                temperature * 1 + 273.15 <= 0
              }
              helperText={
                pressure * 1 <= (applyCorrection ? 20 : 0) ||
                volume * 1 <= 0 ||
                temperature * 1 + 273.15 <= 0
                  ? 'Please enter valid values'
                  : ''
              }
            />
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={applyCorrection}
                onChange={(e) => setApplyCorrection(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography>Apply 20 bar correction? (recommended)</Typography>
            </Box>

            <ButtonGroup sx={{ width: '100%', mt: 1 }}>
              <Button
                variant="contained"
                sx={{
                  width: '50%',
                }}
                onClick={handleInsert}
              >
                Insert
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  width: '50%',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </ButtonGroup>
            {alert.open && (
              <Alert severity={alert.severity}>{alert.message}</Alert>
            )}
          </FormGroup>
        </Box>
      </Modal>
    </>
  );
}

//temperature in degrees celcius
//pressure in bar
//https://www.researchgate.net/figure/Hydrogen-compressibility-factors_tbl1_361314880
//https://h2tools.org/hyarc/hydrogen-data/hydrogen-compressibility-different-temperatures-and-pressures  (used)
const getCompressibility = (pressure, temperature) => {
  //temepratures ()
  const temperatures = [
    -150, -125, -100, -75, -50, -25, 0, 25, 50, 75, 100, 125,
  ];
  const pressures = [1, 10, 50, 100, 300, 500, 1000];
  //
  const compressibility = [
    // pres 1    10      50     100    300      500   1000
    [1.0003, 1.0036, 1.0259, 1.0726, 1.3711, 1.7167, 2.6], //-150 degr
    [1.0006, 1.0058, 1.0335, 1.0782, 1.3231, 1.6017, 2.2856],
    [1.0007, 1.0066, 1.0356, 1.0778, 1.288, 1.5216, 2.1006],
    [1.0007, 1.0068, 1.0355, 1.0751, 1.2604, 1.462, 1.9634],
    [1.0007, 1.0067, 1.0344, 1.0714, 1.2377, 1.4153, 1.8572],
    [1.0006, 1.0065, 1.0329, 1.0675, 1.2186, 1.3776, 1.7725],
    [1.0006, 1.0062, 1.0313, 1.0637, 1.2022, 1.3462, 1.7032], //0
    [1.0006, 1.0059, 1.0297, 1.0601, 1.1879, 1.3197, 1.6454],
    [1.0006, 1.0056, 1.0281, 1.0567, 1.1755, 1.2969, 1.5964],
    [1.0005, 1.0053, 1.0266, 1.0536, 1.1644, 1.277, 1.5542],
    [1.0005, 1.005, 1.0252, 1.0507, 1.1546, 1.2596, 1.5175],
    [1.0005, 1.0048, 1.024, 1.0481, 1.1458, 1.2441, 1.4852], //125 degr
  ];
  if (temperature <= temperatures[0]) {
    return interpolate(pressures, compressibility[0], pressure);
  }
  if (temperature >= temperatures[temperatures.length - 1]) {
    return interpolate(
      pressures,
      compressibility[temperatures.length - 1],
      pressure
    );
  }

  for (let i = 0; i < temperatures.length; i++) {
    if (temperatures[i] > temperature) {
      const Z1 = interpolate(pressures, compressibility[i - 1], pressure);
      const Z2 = interpolate(pressures, compressibility[i], pressure);
      return interpolate(
        [temperatures[i - 1], temperatures[i]],
        [Z1, Z2],
        temperature
      );
    }
  }
};
