import { useContext, useState, useEffect, useRef } from 'react';

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTheme } from '@mui/material/styles';
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import Card from '@mui/material/Card';
import CardBox from './CardBox';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import { DashboardContext } from '@/store/dashboard-context';
import { SettingsContext } from '@/store/settings-context';
import SelectionMenuWrapper from '../utils/SelectionMenuWrapper';
import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { conversion } from '@/utils/formatFunctions';
import { LineStyle } from '@mui/icons-material';
import { debounce } from '@/utils/debounce';
import RenderLoading from './utils/RenderLoading';
export default function GraphCard(props) {
  const { channelData, editMode, changeItem, options, dataLoading } =
    useContext(DashboardContext);
  const { zoom, setZoom } = useContext(SettingsContext);
  const theme = useTheme();

  const chartRef = useRef(null);
  const optionsGraphCard = { ...options };
  delete optionsGraphCard['Station--pos'];
  const { item } = props;
  const { content, dataId } = item;
  const standardHeight = 8.5;
  let data = [];
  let timeStamps = [];
  let dataPlot = [];
  if (dataId in channelData && !editMode) {
    data = channelData[dataId].data;
    timeStamps = channelData[dataId].time;
    // timeStamps = channelData[dataId].time.map((d) => +new Date(d));

    if (dataId in options) {
      data = data.map((d) => {
        return conversion(d, options[dataId].conversion, 20);
      });
    }
    dataPlot = data.map((d, i) => [timeStamps[i], d]);
  }

  function onDataZoom(params) {
    const newZoom = [params.start, params.end];
    setZoom(newZoom);
  }
  const resetZoom = () => {
    setZoom([0, 100]);
  };
  // this is a workaround for using onEvents. The onEvents are causing chart reloads without animation
  useEffect(() => {
    if (!chartRef.current) return;
    let instance = chartRef.current.getEchartsInstance();
    //debounce effect assures that the onEvents are only triggered after 200ms
    //this prevents reloading during changing of the zoom for smooth operation
    instance.on('datazoom', debounce(onDataZoom, 200));
  }, [chartRef.current]);

  const eChartOption = {
    tooltip: {
      trigger: 'axis',
      // position: function (pt) {
      //   return [pt[0], '10%'];
      // },
    },

    xAxis: {
      type: 'time',
      axisLabel: {
        hideOverlap: true,
      },
      // data: timeStamps,
      // axisLabel: { formatter: '{MMM} {yyyy}' },
      // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    grid: {
      left: '14%',
      right: '2%',
      top: '5%',
      bottom: '30%',
      width: 'auto',
      height: 'auto',
    },

    dataZoom: [
      {
        type: 'slider',
        start: zoom[0] == undefined ? 0 : zoom[0],
        end: zoom[1] == undefined ? 100 : zoom[1],
        showDetail: false,
        dataBackground: {
          lineStyle: {
            color: theme.palette.primary.darkTransparent,
          },
          areaStyle: {
            color: theme.palette.primary.darkTransparent,
          },
        },
        fillerColor: theme.palette.primary.lightTransparent,
        moveHandleStyle: {
          color: theme.palette.primary.dark,
        },
        emphasis: {
          moveHandleStyle: {
            color: theme.palette.primary.main,
          },
        },
      },
    ],

    series: [
      {
        type: 'line',
        smooth: true,
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: theme.palette.primary.dark,
        },
        areaStyle: {
          color: theme.palette.primary.areaChart,
        },
        data: dataPlot,
        // data: [150, 230, 224, 218, 135, 147, 260],
      },
    ],
  };

  return (
    <CardBox height={item.size[1] || 2}>
      <Card
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.light',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
              <SelectionMenuWrapper
                buttonProps={{ size: 'small', sx: { margin: 0, padding: 0 } }}
                value={dataId}
                options={optionsGraphCard}
                onChange={changeItem}
                additionalArgsOnChange={[item.position[3]]}
                labelIdentifier="name"
                menuId={`valueMenu-${item.position[3].toString()}`}
                autocompleteProps={{ sx: { width: '15rem' } }}
                onHover={false}
              >
                <MoreVertIcon fontSize="small" sx={{ margin: 0, padding: 0 }} />
              </SelectionMenuWrapper>
            </>
          )}
        </Box>
        <div
          style={{
            position: 'absolute',
            marginTop: '0.2rem',
            padding: 0,
            right: 0,
            top: 0,
          }}
        >
          <IconButton
            sx={{ margin: 0, padding: 0, '&:hover': { color: 'primary.main' } }}
            onClick={() => resetZoom()}
          >
            <HomeIcon fontSize="small" sx={{ margin: '0.2rem', padding: 0 }} />
          </IconButton>
        </div>
        <CardContent sx={{ padding: '0.5rem', paddingTop: '0.5rem' }}>
          {dataId == null ||
          dataId === '' ||
          objEmptyOrUndefined(options) ||
          !(dataId in options) ? (
            <Typography
              variant="body2"
              color="primary.dark"
              sx={{ textAlign: 'center' }}
            >
              Please provide plotting parameter
            </Typography>
          ) : (
            <>
              <Typography
                variant="body2"
                color="primary.dark"
                sx={{ textAlign: 'center' }}
              >
                {`${
                  !objEmptyOrUndefined(options) &&
                  dataId in options &&
                  options[dataId].name
                } [${
                  !objEmptyOrUndefined(options) &&
                  dataId in options &&
                  options[dataId].unit
                }]`}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  height: `${2 * standardHeight - 3}rem`,
                  width: '100%',
                }}
              >
                {dataLoading.session ? (
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <RenderLoading />
                  </Box>
                ) : (
                  <ReactEChartsCore
                    echarts={echarts}
                    option={eChartOption}
                    ref={chartRef}
                    notMerge={false}
                    lazyUpdate={true}
                    style={{ height: '100%', width: '100%' }}
                    // theme={'theme_name'}
                    // onChartReady={this.onChartReadyCallback}
                    // onEvents={{
                    //   // datazoom: debounce(onDataZoom, 200),
                    //   onclick: () => {},
                    // }}
                  />
                )}
              </Box>
            </>
          )}
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const graphCardObj = (position, size) => {
  const obj = {
    type: 'GraphCard',
    typeLabel: `Graph Card ${size[0] / 3}x${size[1]}`,
    size,
    content: {},
    dataId: '',
    position,
  };
  return obj;
};
