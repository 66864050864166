import Link from 'next/link';
import { useEffect, useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import postData from '@/utils/postData';

import { AuthContext } from '@/store/auth-context';
import ModalCloseButton from './utils/ModalCloseButton';
// import { getColumnData } from '../Table/columnDefinitions/unitAssignmentUnitColumns';

export default function AcceptTermsConditionsModal(props) {
  const { openModal, setOpenModal } = props;
  const [accepted, setAccepted] = useState(false);
  const { onLogout, setAcceptedTerms } = useContext(AuthContext);
  const theme = useTheme();
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
    location: 'unit',
  });

  const handleClose = () => {
    if (accepted) {
      setOpenModal(false);
      return;
    }
    handleAlert(
      { open: true, message: 'Please accept or logout', severity: 'error' },
      8
    );
  };

  //show alert and close alert after timeout
  const handleAlert = (alertObj, duration) => {
    setAlert(alertObj);
    setTimeout(() => {
      setAlert({
        open: false,
        message: '',
        severity: 'success',
        location: 'unit',
      });
    }, duration * 1000);
  };

  const handleAccept = async () => {
    //create new invitation
    const [data, success, errorMessage] = await postData(
      {},
      true,
      `/users/acceptTerms`
    );

    if (success) {
      const alertObj = {
        open: true,
        message: 'Accepted terms and conditions',
        severity: 'success',
      };
      handleAlert(alertObj, 8);
      setAccepted(true);
      setAcceptedTerms(true);
      setOpenModal(false);
    } else {
      const alertObj = {
        open: true,
        message: 'Failed to accept: ' + errorMessage,
        severity: 'error',
      };
      handleAlert(alertObj, 8);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflowY: 'scroll',
            maxHeight: '90%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', md: '40rem' },
            p: { xs: '2rem', md: '10rem' },

            bgcolor: 'background.paper',
          }}
        >
          <ModalCloseButton handleClose={handleClose} />
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              color="primary.dark"
              sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
            >
              Terms and conditions
            </Typography>
            <Typography variant="body2">
              {' '}
              Terms and conditions have been updated or have not yet been
              accepted.{' '}
            </Typography>

            <Typography sx={{ mt: 2 }} variant="body2">
              By pressing accept you agree with our{' '}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1_NmT7kJcUFl_ZG0fY5smMk8lbUjG6Tbs/view"
              >
                general terms and conditions
              </a>{' '}
              and acknowledge reading our{' '}
              <a target="_blank" href="https://watermeln.com/en/privacy-policy">
                privacy policy
              </a>
            </Typography>

            <ButtonGroup sx={{ mt: 5, width: '100%' }}>
              <Button
                color="secondary"
                variant="outlined"
                sx={{
                  width: '50%',
                }}
                onClick={onLogout}
              >
                Logout
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{ width: '50%' }}
                onClick={handleAccept}
              >
                Accept
              </Button>
            </ButtonGroup>

            {alert.open && (
              <Alert sx={{ mt: 2 }} severity={alert.severity}>
                {alert.message}
              </Alert>
            )}
          </FormGroup>
        </Box>
      </Modal>
    </>
  );
}
