import { useEffect, useState, useContext } from 'react';
import { AuthContext } from '@/store/auth-context';
import GeneralDialogMenu from '../utils/GeneralDialogMenu';
import AcceptTermsConditionsModal from '../Modals/AcceptTermsConditionsModal';
import { debounce } from '@/utils/debounce';
export default function EnforceTermsConditions(props) {
  const { acceptedTerms, isLoggedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  //   const changeModal = debounce((state) => setOpen(state), 5000);
  useEffect(() => {
    if (!acceptedTerms && isLoggedIn) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [acceptedTerms, isLoggedIn]);
  return <AcceptTermsConditionsModal openModal={open} setOpenModal={setOpen} />;
}
