import { debounce } from '@/utils/debounce';
import { useState, useEffect, useCallback } from 'react';

//email validation from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateName = (name) => {
  const listOfForbiddenNames = ['admin'];
  return !listOfForbiddenNames.includes(
    String(name)
      .toLowerCase() //to lower case
      .replace(' ', '') //remove spaces
      .replace(/[^a-zA-Z ]/g, '') //remove all special characters
  );
};

const validatePassword = (password, passwordConfirm) => {
  if (password.length < 8) {
    return [false, 'Password to short'];
  }
  if (password !== passwordConfirm) {
    return [false, 'Passwords do not match'];
  }
  if (
    !String(password).match(
      /^(?=.*[0-9])(?=.*[!@#$%^&-_".~()*])[a-zA-Z0-9!@#$%^&-_".~()*]{8,20}$/
    )
  ) {
    return [
      false,
      'Password must contain at least one number and one special character, minimum length 8, maximum length 20',
    ];
  }
  return [true, ''];
};

export function useEmail() {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  //perform validation of email in a delayed fasion
  const performValidation = useCallback(
    debounce((em) => {
      setIsEmailValid(validateEmail(em));
    }, 300),
    []
  );

  //check for changes in email
  useEffect(() => {
    //delay the validation of the email for 500ms
    performValidation(email);
  }, [email]);

  //export for external usage
  return {
    email,
    setEmail,
    isEmailValid,
  };
}

export function useFirstName() {
  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);

  //perform validation of email in a delayed fasion
  const performValidation = useCallback(
    debounce((name) => {
      setIsFirstNameValid(validateName(name));
    }, 300),
    []
  );

  //check for changes in firstName
  useEffect(() => {
    //delay the validation of the email for 500ms
    performValidation(firstName);
  }, [firstName]);
  return {
    firstName,
    setFirstName,
    isFirstNameValid,
  };
}

export function usePassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [message, setMessage] = useState('');

  //perform validation of email in a delayed fasion
  const performValidation = useCallback(
    debounce((pass, passConfirm) => {
      const [isValid, message] = validatePassword(pass, passConfirm);
      setMessage(message);
      setIsPasswordValid(isValid);
    }, 300),
    []
  );

  //check for changes in password
  useEffect(() => {
    //delay the validation of the email for 500ms
    performValidation(password, passwordConfirm);
  }, [password, passwordConfirm]);

  return {
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isPasswordValid,
    passwordErrorMessage: message,
  };
}
