export const calcHeight = (height) => {
  const standardHeight = 8.5;
  return `calc(${height * standardHeight}rem + ${height - 1}px)`;
};

export const calcHeightPadding = (height, isMobilePadding, buffer = '0px') => {
  const standardHeight = 8.5;
  const paddingHeight = isMobilePadding ? '0.2rem' : '0.5rem';
  return `calc(${height * standardHeight}rem + ${
    height - 1
  }px - ${paddingHeight} - ${buffer})`;
};
