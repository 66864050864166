import { useEffect, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { DashboardContext } from "@/store/dashboard-context";

export default function GeneralDialogMenu(props) {
  const { dialog, setDialog } = props;

  const handleClose = () => {
    setDialog({ ...dialog, open: false });
  };
  return (
    <Dialog
      open={dialog.open}
      onClose={dialog.handleClose ? dialog.handleClose : handleClose}
      aria-labelledby={dialog.title}
      aria-describedby={`${dialog.title}-description`}
    >
      <DialogTitle id={dialog.title}>{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${dialog.title}-description`}>
          {dialog.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialog.acceptAction} autoFocus variant="contained">
          {dialog.acceptLabel}
        </Button>
        <Button onClick={dialog.cancelAction}>{dialog.cancelLabel}</Button>
      </DialogActions>
    </Dialog>
  );
}
