import { useState, useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material';

import postData from '@/utils/postData';
import { AuthContext } from '@/store/auth-context';
import RegisterForm from './RegisterForm';
function VerifyInvite(props) {
  //get dynamic path
  const router = useRouter();
  const validationToken = router.query.validationToken;

  //messaging state
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('error');
  const [registerRequired, setRegisterRequired] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [email, setEmail] = useState('');

  //check if validation has been send:
  const effectCalled = useRef(false);
  //context
  const { setFormToShow, openLoginModal } = useContext(AuthContext);
  //to apply color
  const theme = useTheme();
  console.log('test');
  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
    }
  }, [openLoginModal]);

  useEffect(() => {
    if (!effectCalled.current) {
      effectCalled.current = true;
      setIsValidated(false);
      handleSubmit();
    }
  }, []);
  //submit form to backend for login
  //check for email and password
  //message on error and close on succes
  const handleSubmit = async () => {
    //check on validations

    const [data, success, postMessage] = await postData(
      null,
      true,
      `/invitations/verifyInvitation/${validationToken}`
    );
    if (!success) {
      setAlertType('error');
      setOpenAlert(true);
      setMessage(postMessage);
    } else {
      setAlertType('success');
      setMessage(postMessage);
      setOpenAlert(true);
      setTimeout(() => {
        setIsValidated(true);
      }, 6000);
      router.replace('/');
      console.log(data);
      if (data.registerRequired) {
        setRegisterRequired(true);
        setEmail(data.email);
      } else {
        setRegisterRequired(false);
      }
    }
  };

  return (
    <>
      <FormGroup>
        {!isValidated && (
          <Typography
            variant="h6"
            color="secondary.contrastText"
            sx={{ mb: 1, fontWeight: 'bold' }}
          >
            Verifying your email
          </Typography>
        )}
        {!isValidated && openAlert && (
          <Alert severity={alertType}>{message}</Alert>
        )}
        {isValidated && !registerRequired && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              '&.Mui-disabled': {
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
              },
              width: '100%',
            }}
            onClick={() => setFormToShow('login')}
          >
            {'<to Login'}
          </Button>
        )}
        {isValidated && registerRequired && (
          <RegisterForm initialEmail={email} />
        )}
      </FormGroup>
    </>
  );
}
export default VerifyInvite;
