import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
export const PaginationTable = (props) => {
  const { goToPage, pageIndex, pageSize, setPageSize, rowCount, pageCount } =
    props;

  const handleChange = (event, value) => {
    goToPage(value - 1);
  };
  const handlePageCountChange = (e) => {
    setPageSize(e.target.value);
  };

  //   ComplexTable-pagination
  return (
    <div className="centralize_content">
      <div style={{ display: 'flex' }}>
        <Pagination
          component="div"
          count={pageCount}
          page={pageIndex + 1}
          onChange={handleChange}
          color="primary"
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pageSize}
          onChange={handlePageCountChange}
          size="small"
          variant="outlined"
          label="count"
          sx={{
            borderRadius: '20px',
            backgroundColor: 'primary',
          }}
        >
          {/* set menu items for 25, 50, 100, 250, 500,1000 */}
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
        </Select>
      </div>
    </div>
  );
};
export default PaginationTable;
