import { useEffect, useState } from 'react';
export const useContainerDimensions = (myRef, dependancy) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => {
      if (myRef.current) {
        return {
          width: myRef.current.offsetWidth,
          height: myRef.current.offsetHeight,
        };
      } else {
        return { width: 10, height: 10 };
      }
    };

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, dependancy]);

  return dimensions;
};
