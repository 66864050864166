import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DashboardContext } from '@/store/dashboard-context';
import postData from '@/utils/postData';
import ModalCloseButton from './utils/ModalCloseButton';
import CalculateIcon from '@mui/icons-material/Calculate';
import IconButton from '@mui/material/IconButton';
import FuelCalculatorModal from './FuelCalculatorModal';
import { conversion } from '@/utils/formatFunctions';
import fetchData from '@/utils/fetchData';
import ComplexTable from '../Table/ComplexTable';
import { getColumnData } from '../Table/columnDefinitions/fuelStatusHistoryColumns';
import { Typography } from '@mui/material';

export default function FuelCardModal(props) {
  const { unitId, openModal, setOpenModal } = props;
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [dateTime, setDateTime] = useState(moment());
  const [h2Available, setH2Available] = useState(0);
  const [update, setUpdate] = useState(false);
  const [openCalcModal, setOpenCalcModal] = useState(false);
  const [historicStatusData, setHistoricStatusData] = useState([]);
  moment.locale('en-gb');
  const handleClose = () => {
    setOpenModal(false);
  };
  const theme = useTheme();
  //show alert and close alert after timeout
  const handleAlert = (alertObj, duration) => {
    setAlert(alertObj);
    setTimeout(() => {
      setAlert({ open: false, message: '', severity: 'success' });
    }, duration * 1000);
  };
  function isNumeric(str) {
    if (isNaN(Number(str))) {
      return false;
    }
    return true;
  }

  // handle change of form for numbers
  const handleH2Value = (e) => {
    let value = e.target.value;
    if (value === '') {
      setH2Available('');
    }

    if (!isNumeric(value)) return;
    try {
      value = parseFloat(value);
    } catch (error) {
      return;
    }
    //check if nan
    if (isNaN(value)) return;

    setH2Available(value);
  };

  const handleSave = (updateDoc) => async () => {
    //do data sanity checks
    if (
      h2Available < 0 ||
      h2Available === '' ||
      !isNumeric(h2Available) ||
      h2Available === 0 ||
      isNaN(h2Available) ||
      h2Available > 5000
    ) {
      //show alert with invalid h2 value message
      const alertObj = {
        open: true,
        message: 'Invalid h2 value',
        severity: 'error',
      };
      handleAlert(alertObj, 8);
      return;
    }
    if (unitId == null) {
      //show alert with invalid unit id message
      const alertObj = {
        open: true,
        message: 'Invalid unit id',
        severity: 'error',
      };
      handleAlert(alertObj, 8);
      return;
    }

    const [data, success, errorMessage] = await postData(
      { unit: unitId, time: dateTime, h2Available: h2Available * 1000 },
      updateDoc,
      '/fuelStatus'
    );
    if (success) {
      const alertObj = {
        open: true,
        message: 'Saved successfully',
        severity: 'success',
      };
      handleAlert(alertObj, 8);
      setTimeout(() => {
        handleClose();
      }, 2000);
    } else {
      const alertObj = {
        open: true,
        message: 'Failed to save: ' + errorMessage,
        severity: 'error',
      };
      handleAlert(alertObj, 8);
    }
  };

  //fetch previous fuel settings
  useEffect(() => {
    //load data
    const getData = async () => {
      const [data, success, errorMessage] = await fetchData(
        `/fuelStatus?limit=5&unit=${unitId}`
      );

      if (success) {
        setHistoricStatusData(data);
      } else {
        setHistoricStatusData([]);
      }
    };
    if (openModal) {
      getData();
    }
  }, [unitId, openModal]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              overflowY: 'scroll',
              maxHeight: '90%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: '70rem' },
              p: { xs: '2rem', sm: '10rem' },
              bgcolor: 'background.paper',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ModalCloseButton handleClose={handleClose} />

              <FormGroup sx={{ maxWidth: '20rem', justifyContent: 'center' }}>
                <Typography variant="h6" color={'primary.dark'}>
                  Add refueling information
                </Typography>
                <DateTimePicker
                  label="Filling date and time"
                  value={dateTime}
                  onChange={(newValue) => {
                    setDateTime(newValue);
                  }}
                  sx={{ mt: 2, maxWidth: '20rem' }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      // focused: true,
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Total H2 Installed [kg]"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  onChange={handleH2Value}
                  value={conversion(h2Available, null, 1)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ mr: 0, pr: 0 }}
                        onClick={() => setOpenCalcModal(true)}
                      >
                        <CalculateIcon />
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={handleSave(false)}
                >
                  Save
                </Button>

                {alert.open && (
                  <Alert severity={alert.severity}>{alert.message}</Alert>
                )}
              </FormGroup>
              <Box sx={{ mt: 1, width: '100%' }}>
                <Typography
                  variant="h6"
                  color={'primary.dark'}
                  sx={{ mt: 5, textAlign: 'center' }}
                >
                  Historic data
                </Typography>

                <ComplexTable
                  data={historicStatusData}
                  columns={getColumnData(theme)}
                  rowStyle={{
                    borderBottom: '1px solid',
                    borderBottomColor: `primary.light`,
                    color: 'primary.dark',
                    '&:hover': {
                      color: 'secondary.main',
                    },
                  }}
                  tableStyle={{
                    borderCollapse: 'collapse',
                    overflowX: 'auto',
                  }}
                  tableDivStyle={{
                    marginTop: '2rem',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <FuelCalculatorModal
            openModal={openCalcModal}
            setOpenModal={setOpenCalcModal}
            setMass={setH2Available}
          />
        </LocalizationProvider>
      </Modal>
    </>
  );
}
