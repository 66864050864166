import { useEffect, useState } from 'react';
import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
export default function MapMarker(props) {
  const { center, height, styles } = props;
  const [mapCenter, setMapCenter] = useState(center);

  const icon = {
    url: '/images/favicon/favicon-32x32.png', // url
    // scaledSize: new map.Size(24, 24), // scaled size
    // origin: new map.Point(0, 0), // origin
    // anchor: new map.Point(0, 0), // anchor
  };

  //   <AdvancedMarker
  //           position={{lat: 15, lng: 20}}
  //           title={'AdvancedMarker with customized pin.'}>
  //           <Pin background={'#22ccff'} borderColor={'#1e89a1'} scale={1.4}>
  //             {/* children are rendered as 'glyph' of pin */}
  //             👀
  //           </Pin>
  //         </AdvancedMarker>
  useEffect(() => {
    setMapCenter(center);
  }, [center]);
  return (
    <Map
      center={mapCenter}
      onCenterChanged={(e) => {
        setMapCenter(e.center);
      }}
      //   defaultCenter={center}
      defaultZoom={10}
      style={{
        width: '100%',
        height,
      }}
      styles={styles}
    >
      <Marker position={center} icon={icon} />
    </Map>
  );
}
