import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export default function ModalCloseButton(props) {
  const { handleClose } = props;
  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
