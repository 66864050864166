import { useContext, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardBox from './CardBox';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useIsMobile from '@/hooks/useIsMobile';
import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import { DashboardContext } from '@/store/dashboard-context';
import { AlarmContext } from '@/store/alarm-context';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import SelectionMenuWrapper from '../utils/SelectionMenuWrapper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { timestampToLocalDateTimeShort } from '@/utils/dateUtil';
import RenderLoading from './utils/RenderLoading';
export default function AlarmCard(props) {
  const { item } = props;
  const { editMode, changeAlarm, alarmOptions, alarmData, dataLoading } =
    useContext(DashboardContext);
  const { alarmData: alarmMessage } = useContext(AlarmContext);
  const [highlight, setHighlight] = useState({});
  const [lineHeight, setLineHeight] = useState(20);
  const titleRef = useRef();

  const isMobile = useIsMobile('sm');

  const { content } = item;

  let currentState;
  let lastStateChange;

  if (
    item.alarmTemplateId != null &&
    item.alarmTemplateId !== '' &&
    item.alarmTemplateId in alarmData
  ) {
    currentState =
      'currentState' in alarmData[item.alarmTemplateId]
        ? alarmData[item.alarmTemplateId].currentState
        : '';
    lastStateChange =
      'lastStateChange' in alarmData[item.alarmTemplateId]
        ? alarmData[item.alarmTemplateId].lastStateChange
        : '';
  }

  //set the line height
  useEffect(() => {
    titleRef.current;
    if (titleRef.current) {
      const lineHeightNew = window
        .getComputedStyle(titleRef.current, null)
        .getPropertyValue('line-height');
      setLineHeight(parseFloat(lineHeightNew.replace('px', '')));
    }
  }, []);

  //check if we are mentioned in the alarm
  //such that we can highlight the card
  useEffect(() => {
    //check
    if (
      !objEmptyOrUndefined(alarmMessage) &&
      alarmMessage.category === 'cardNonEmptyError'
    ) {
      //check if we are mentioned in the alarm
      const foundSelf = alarmMessage.data.nonEmpty.filter(
        (card) => card.position[3] == item.position[3]
      );
      if (foundSelf.length > 0) {
        setHighlight({
          '@keyframes pulse': {
            '0%, 100%': {
              backgroundColor: 'primary.light',
            },
            '50%': {
              backgroundColor: 'alarm.danger',
            },
          },
          animation: 'pulse 1s infinite',
        });
        setTimeout(() => {
          setHighlight({});
        }, alarmMessage.duration);
      }
    }
  }, [alarmMessage]);

  const renderResult = () => {
    return (
      <>
        <Typography
          variant={
            isMobile || currentState == null || currentState === ''
              ? 'h6'
              : 'h6'
          }
          sx={{ fontWeight: 'bold' }}
          color="primary.dark"
        >
          {currentState == null || currentState === ''
            ? 'Status Unavailable'
            : currentState.replace('danger', 'alert').toUpperCase()}
        </Typography>
        {(currentState == null || currentState === '') && (
          <Typography variant="caption">
            State will be updated on next data round
          </Typography>
        )}
        {lastStateChange == null || lastStateChange === '' ? (
          ''
        ) : (
          <>
            <Typography variant="caption">Since </Typography>
            <Typography variant="caption">
              {timestampToLocalDateTimeShort(lastStateChange)}
            </Typography>
          </>
        )}
      </>
    );
  };

  return (
    <CardBox height={1}>
      <Card
        sx={{
          width: '100%',
          paddingTop: editMode ? '1.2rem' : 0,
          height: '100%',
          backgroundColor: 'primary.light',
          borderBottom: 5,
          position: 'relative',
          ...highlight,
          borderColor:
            currentState === '' ? 'alarm.unavailable' : `alarm.${currentState}`,
        }}
      >
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
              <SelectionMenuWrapper
                buttonProps={{ size: 'small', sx: { margin: 0, padding: 0 } }}
                value={item.alarmTemplateId}
                options={alarmOptions}
                onChange={changeAlarm}
                additionalArgsOnChange={[item.position[3], alarmOptions]}
                labelIdentifier="name"
                menuId={`valueMenu-${item.position[3].toString()}`}
                autocompleteProps={{ sx: { width: '15rem' } }}
                onHover={false}
              >
                <MoreVertIcon fontSize="small" sx={{ margin: 0, padding: 0 }} />
              </SelectionMenuWrapper>
            </>
          )}
        </Box>

        <CardContent sx={{ padding: '0.5rem' }}>
          {item.alarmTemplateId == null ||
          item.alarmTemplateId === '' ||
          !(item.alarmTemplateId in alarmOptions) ? (
            <>
              <Box sx={{ minHeight: `${lineHeight * 2}px` }}>
                <Typography
                  variant={isMobile ? 'caption' : 'body2'}
                  color="primary.dark"
                >
                  {item.alarmTemplateId !== '' &&
                  item.alarmTemplateId != null &&
                  !(item.alarmTemplateId in alarmOptions)
                    ? 'Alarm definition might have been removed, please create new alarm'
                    : 'Please configure alarm'}
                </Typography>
              </Box>
              <Typography
                variant={isMobile ? 'body2' : 'subtitle1'}
                sx={{ fontWeight: 'bold' }}
                color="primary.dark"
              >
                Alarm Card
              </Typography>
            </>
          ) : (
            <>
              <Box sx={{ minHeight: `${lineHeight * 2}px` }}>
                <Typography
                  variant={isMobile ? 'caption' : 'body2'}
                  color="primary.dark"
                  ref={titleRef}
                >
                  {alarmOptions[item.alarmTemplateId].name}
                </Typography>
              </Box>

              {dataLoading.alarm ? <RenderLoading /> : renderResult()}
            </>
          )}
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}
export const alarmCardObj = (position, size) => {
  const obj = {
    type: 'AlarmCard',
    typeLabel: `Alarm Card ${size[0] / 3}x${size[1]}`,
    content: {},
    dataId: '',
    alarmTemplateId: '',
    size,
    position,
  };
  return obj;
};
