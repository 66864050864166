import moment from 'moment';

export const timestampToLocalDateTime = (timeStamp) => {
  return moment(timeStamp).local().format('YYYY-MM-DD HH:mm:ss');
};
export const timestampToLocalDateTimeShort = (timeStamp) => {
  return moment(timeStamp).local().format('DD-MM HH:mm:ss');
};
export const timestampToLocalDateTimeShortest = (timeStamp) => {
  return moment(timeStamp).local().format('DD-MM HH:mm');
};
export const timestampToLocalShortDate = (timeStamp) => {
  return moment(timeStamp).local().format('DD/MM');
};

export const compareDates = (date1, date2, level = 'day') => {
  //check if level is year, month, day, hour, minute or second
  if (
    !(
      level === 'year' ||
      level === 'month' ||
      level === 'day' ||
      level === 'hour' ||
      level === 'minute' ||
      level === 'second'
    )
  ) {
    throw new Error('level must be year, month, day, hour, minute or second');
  }

  if (date1.getFullYear() !== date2.getFullYear()) {
    return false;
  }
  if (level === 'year') {
    return true;
  }
  if (date1.getMonth() !== date2.getMonth()) {
    return false;
  }
  if (level === 'month') {
    return true;
  }
  if (date1.getDate() !== date2.getDate()) {
    return false;
  }
  if (level === 'day') {
    return true;
  }
  if (date1.getHours() !== date2.getHours()) {
    return false;
  }

  if (level === 'hour') {
    return true;
  }
  if (date1.getMinutes() !== date2.getMinutes()) {
    return false;
  }
  if (level === 'minute') {
    return true;
  }
  if (date1.getSeconds() !== date2.getSeconds()) {
    return false;
  }
  //if you want level miliseconds you can just do getTime so this function does not make sense to detail further
  return true;
};

//given a start and end date, create a list of dates and duration of data available
export const getDurations = (startDate, endDate) => {
  const days = [];
  let date = new Date(startDate);

  while (date.getTime() <= endDate) {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    const dateString = localDate.toISOString().split('T')[0];

    let endOfDay = new Date(new Date(date.getTime()).setHours(23, 59, 59, 999));
    if (endOfDay.getTime() > endDate) {
      endOfDay = new Date(endDate);
    }
    //duration in hours
    const duration = (endOfDay.getTime() - date.getTime()) / 1000 / 60 / 60;
    days.push([dateString, duration]);
    date = new Date(date.setHours(24));
  }
  return days;
};
