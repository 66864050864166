import React, { useState, useContext } from 'react';
import fetchData from '@/utils/fetchData';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from '@/components/theme';
import Link from 'next/link';
import { popoverClasses } from '@mui/material/Popover';
import { AuthContext } from '@/store/auth-context';
import postData from '@/utils/postData';

function Account(props) {
  const {
    onLogout,
    onLogoutClient,
    postDataContext,
    setTriggerLogin,
    setFormToShow,
  } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let timeoutId = null;
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (!!timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setAnchorEl(null);
    }, 500);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuEnter = () => {
    if (!!timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  //function to handle logout
  const handleLogout = () => {
    onLogout();
  };
  //handle profile
  const handleProfile = () => {
    setFormToShow('updateProfile');
    setTriggerLogin(true);
  };
  //function handle get me
  const handleGetMe = async () => {
    const [data, success, errorMessage] = await fetchData(
      '/users/me',
      null,
      onLogoutClient
    );
    console.log('try to get me: ', errorMessage);
  };
  //funtion handle test
  const handleTest = async () => {
    const [data, success, errorMessage] = await postDataContext(
      null,
      false,
      '/users/test'
    );
    console.log('try to get post: ', errorMessage);
  };
  return (
    <div>
      <IconButton
        color="secondary"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={handleClose}
        // sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          onMouseOver: handleMenuEnter,
          onMouseLeave: handleMenuClose,
        }}
        sx={{
          [`&.${popoverClasses.root}`]: { pointerEvents: 'none' },
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.primary.main,
            border: '1px solid',
            borderColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.contrastText,
            pointerEvents: 'auto',
          },
        }}
        aria-labelledby="demo-positioned-button"
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark, // Light blue hover color
            },
          }}
          onClick={() => {
            handleLogout();
            handleMenuClose();
          }}
        >
          Logout
        </MenuItem>
        <MenuItem
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark, // Light blue hover color
            },
          }}
          onClick={() => {
            handleProfile();
            handleMenuClose();
          }}
        >
          Profile
        </MenuItem>
      </Menu>
    </div>
  );
}
export default Account;
