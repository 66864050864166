import { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DashboardElement from './DashboardElement';
import { DashboardContext } from '@/store/dashboard-context';
import CardCustomization from './customization/CardCustomization';
export default function NestedCard(props) {
  const { item } = props;
  const cardItems = item.content;
  const { insertEmptyCard, removeCard, items, editMode } =
    useContext(DashboardContext);
  const standardHeight = 8.5;
  const height = 1;
  useEffect(() => {
    //check if all items are empty if so we must convert the nested card into a regular card

    let totalSize = 0;
    cardItems.forEach((it) => (totalSize += it.size[0]));

    if (totalSize > 12) {
      let lastEmpty = -1;
      cardItems.forEach((card, index) => {
        if (card.type == 'EmptyCard') {
          lastEmpty = index;
        }
      });
      if (lastEmpty >= 0) {
        //delete card
        removeCard([item.position[3][0], lastEmpty]);
      }
    }
  }, [items]);

  return (
    <>
      <Grid
        container
        justifyContent={'flex-start'}
        sx={{
          width: '100%',
        }}
      >
        {editMode && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                margin: '0.5rem',
                padding: 0,
                backgroundColor: 'primary.lightTransparent',
                width: 'calc(100% - 1rem)',
                justifyContent: 'center',
                alignItems: 'center',
                border: 1,
                borderRadius: '1rem',
                top: 0,
                zIndex: 10,
              }}
            >
              <CardCustomization item={item} />
            </Box>
          </Box>
        )}

        {cardItems.map((nestedCardItem, index) => (
          <Grid
            item
            xs={nestedCardItem.size[0]}
            key={index}
            sx={{ width: '100%' }}
          >
            <DashboardElement item={nestedCardItem} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export const nestedCardObj = (position) => {
  const obj = {
    type: 'NestedCard',
    typeLabel: 'Nested Card 4x1',
    content: [],
    size: [12, 1],
    position,
  };
  return obj;
};
