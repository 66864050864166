import { useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getTextfieldSx } from '@/utils/textFieldBorderColor';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  '& label': {
    color: theme.palette.primary.dark,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.primary.dark,
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: theme.palette.primary.dark,
  },
  '& input::placeholder': {
    color: 'green',
  },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.primary.dark,
  },
  '& .MuiInputBase-root': {
    color: theme.palette.primary.dark,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    color: theme.palette.primary.dark,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },

  '& legend': {
    color: theme.palette.primary.dark,
  },
}));

export default function RegularSelect(props) {
  const {
    value,
    options,
    handleChange,
    renderInput = (idx) => options[idx],
    renderOption,
    label,
    keyVal,
    borderColor,
    sxProps = {},
    textFieldProps = { width: '12rem', marginTop: '0.5rem' },
  } = props;

  let sxValue = {};
  if (borderColor != null) {
    sxValue = getTextfieldSx(borderColor);
  }

  if (sxProps) {
    sxValue = {
      ...sxValue,
      ...sxProps,
    };
  }
  const fieldValue = value === -1 ? '' : value;

  return (
    <TextField
      select
      label={label}
      // labelId={`${key}-label`}
      id={`${keyVal}-select`}
      value={fieldValue}
      onChange={handleChange}
      // MenuProps={MenuProps}
      // renderValue={renderInput}
      sx={sxValue}
      {...textFieldProps}
    >
      {options.map((option, idx) => (
        <MenuItem
          key={`${keyVal}-menu-${idx}`}
          value={idx}
          sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
        >
          {renderOption(option)}
        </MenuItem>
      ))}
    </TextField>
  );
}
