import { useRouter } from 'next/router';
import { useContext, useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { DashboardContext } from '@/store/dashboard-context';
import { AlarmContext } from '@/store/alarm-context';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import CardBox from './CardBox';
import useIsMobile from '@/hooks/useIsMobile';

import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import FuelCardModal from '../Modals/FuelCardModal';
import { AuthContext } from '@/store/auth-context';
import RenderLoading from './utils/RenderLoading';
import { conversion } from '@/utils/formatFunctions';

const WatermelnSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  paddingBottom: '0px',
  '& .MuiSlider-track': {
    border: 'none',
    paddingBottom: '0px',
    marginBottom: '0px',
  },
  '& .MuiSlider-root': {
    paddingBottom: '0px',
    marginBottom: '0px',
  },
  '& .MuiSlider-thumb': {
    height: 8,
    width: 8,
    backgroundColor: theme,
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 9,
    background: 'unset',
    padding: 0,
    width: 41,
    height: 20,
    // borderRadius: '20%',
    // borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    // transformOrigin: 'bottom left',
    // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    // '&::before': { display: 'none' },
    // '&.MuiSlider-valueLabelOpen': {
    //   transform: 'translate(50%, -80%) rotate(-45deg) scale(1)',
    // },
    // '& > *': {
    //   transform: 'rotate(45deg)',
    // },
  },
}));

export default function FuelCard(props) {
  const { valueData, editMode, options, dataLoading } =
    useContext(DashboardContext);
  const { alarmData } = useContext(AlarmContext);
  const { restrictTo } = useContext(AuthContext);
  const [highlight, setHighlight] = useState({});
  const [showPercentage, setShowPercentage] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const router = useRouter();
  const { id: unitId } = router.query;
  const optionsValueCard = { ...options };
  delete optionsValueCard['Station--pos'];

  const { item } = props;
  const { content, dataId } = item;
  const isMobile = useIsMobile('sm');

  const getMaxValue = () => {
    return valueData?.h2LastRefill != null
      ? valueData.h2LastRefill / 1000
      : 500;
  };

  const getMaxVAlueShow = () => {
    if (showPercentage) return 100;
    return getMaxValue();
  };

  const getValue = (x) => {
    if (showPercentage) return (x / 1000 / getMaxValue()) * 100;
    return x / 1000;
  };

  useEffect(() => {
    //check
    if (
      !objEmptyOrUndefined(alarmData) &&
      alarmData.category === 'cardNonEmptyError'
    ) {
      //check if we are mentioned in the alarm
      const foundSelf = alarmData.data.nonEmpty.filter(
        (card) => card.position[3] == item.position[3]
      );
      if (foundSelf.length > 0) {
        setHighlight({
          '@keyframes pulse': {
            '0%, 100%': {
              backgroundColor: 'primary.light',
            },
            '50%': {
              backgroundColor: 'alarm.danger',
            },
          },
          animation: 'pulse 1s infinite',
        });
        setTimeout(() => {
          setHighlight({});
        }, alarmData.duration);
      }
    }
  }, [alarmData]);

  const renderResult = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <WatermelnSlider
          track="inverted"
          aria-labelledby="track-inverted-slider"
          // getAriaValueText={valuetext}
          value={
            valueData?.fuelAvailable != null
              ? getMaxVAlueShow() - getValue(valueData.fuelAvailable)
              : 0
          }
          valueLabelFormat={(x) => {
            return `${(getMaxVAlueShow() - x).toFixed(0)} ${
              showPercentage ? '%' : 'kg'
            }`;
          }}
          disabled={false}
          marks={false}
          max={getMaxVAlueShow()}
          min={0}
          size="small"
          valueLabelDisplay="on"
          sx={{
            mt: '1.5rem',
            ml: '5%',
            mb: { xs: -1, md: 0 },
            width: '90%',
            pb: '0px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 0,
            mb: { xs: -3, md: -1 },
            p: 0,
          }}
        >
          <Typography color="primary.dark" variant="caption" sx={{ ml: '2%' }}>
            {conversion(getMaxVAlueShow(), null, 0)}
          </Typography>
          <Typography color="primary.dark" variant="caption" sx={{ mr: '2%' }}>
            {0}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <CardBox height={1}>
      <Card
        sx={{
          paddingTop: editMode ? '1.2rem' : '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.light',
          position: 'relative',
          ...highlight,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            mr: 1,
            right: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography color="primary.dark">kg</Typography>
          <Switch
            checked={showPercentage}
            onChange={() => setShowPercentage((prev) => !prev)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography color="primary.dark">%</Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-end',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent sx={{ padding: '0.5rem' }}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant={isMobile ? 'caption' : 'body2'}
              color="primary.dark"
            >
              Hydrogen Available
            </Typography>
            {dataLoading.value ? <RenderLoading /> : renderResult()}

            {restrictTo(['admin', 'owner_admin']) && (
              <Button onClick={() => setOpenModal(true)} sx={{ mt: 0 }}>
                Update Fuel
              </Button>
            )}
          </Box>

          <TransparentLayer item={item} />
          <FuelCardModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            unitId={unitId}
          />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const fuelCardObj = (position, size) => {
  const obj = {
    type: 'FuelCard',
    typeLabel: `Fuel Card ${size[0] / 3}x${size[1]}`,
    content: {},
    dataId: '',
    size,
    position,
  };
  return obj;
};
