import { useEffect, useContext, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { DashboardContext } from '@/store/dashboard-context';
import { AuthContext } from '@/store/auth-context';
import UpdateIcon from '@mui/icons-material/Update';
import CardBox from './CardBox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import CardContent from '@mui/material/CardContent';
import { useContainerDimensions } from '@/hooks/useDimensions';
import InfoBox from './utils/InfoBox';
import StatusBox from './utils/StatusBox';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { conversion } from '@/utils/formatFunctions';
import useIsMobile from '@/hooks/useIsMobile';
import { dataAvailable } from './utils/dataAvailable';
import { timestampToLocalDateTimeShort } from '@/utils/dateUtil';

export default function OverviewCard(props) {
  const { item, nColumns } = props;
  const { content, dataId, size } = item;
  const { valueData, editMode, options, unitIsOnline, unitData } =
    useContext(DashboardContext);

  const [videoUrl, setVideoUrl] = useState('');
  const isMobile = useIsMobile();
  const cardRef = useRef();
  const videoRef = useRef();
  const { width: cardWidth, height: cardHeight } = useContainerDimensions(
    cardRef,
    nColumns
  );
  const videoHeight = Math.floor(cardHeight * 0.75);

  const getValue = (dataId) => {
    if (objEmptyOrUndefined(valueData)) return '';
    if (!(dataId in valueData)) return '';

    if (dataId == 'Accu--Ugem') {
      return conversion(valueData[dataId], options[dataId].conversion, 2);
    } else if (dataId == 'VectoPower--F1') {
      return conversion(valueData[dataId], options[dataId].conversion, 0);
    } else if (dataId in options) {
      return conversion(
        valueData[dataId],
        options[dataId].conversion,
        options[dataId].decimals
      );
    } else {
      return valueData[dataId];
    }
  };

  //determine what video needs to be played
  //we have states for AC_IN, AC_OUT, and FC. This gives 8 different videos
  const getMovieString = () => {
    let videoString = '';
    if (!unitIsOnline) return 'unitOff.webm';
    //check if AC_IN is on
    let dataIdVid = 'OBC--Vermogen';
    if (
      !objEmptyOrUndefined(valueData) &&
      dataIdVid in valueData &&
      valueData[dataIdVid] > 0
    ) {
      videoString = 'AC_IN';
    }

    //check if AC_OUT is on
    dataIdVid = 'VectoPower--Power';
    if (
      !objEmptyOrUndefined(valueData) &&
      dataIdVid in valueData &&
      valueData[dataIdVid] > 0
    ) {
      videoString = videoString === '' ? 'AC_OUT' : videoString + '_AC_OUT';
    }

    //check if FC is on
    dataIdVid = 'FuelCell--Vermogen';
    if (
      !objEmptyOrUndefined(valueData) &&
      dataIdVid in valueData &&
      valueData[dataIdVid] > 0
    ) {
      videoString = videoString === '' ? 'FC_ON' : videoString + '_FC_ON';
    }
    //if we have nothing the unit is not doing anything
    if (videoString === '') videoString = 'unitOff';

    //add extension
    return videoString + '.webm';
  };

  const parseUnit = (dataId) => {
    let unit = '';

    if (
      !objEmptyOrUndefined(options) &&
      dataId in options &&
      options[dataId].unit
    )
      unit = options[dataId].unit;

    if (unit === '°') return '°';
    else {
      return ` ${unit}`;
    }
  };

  useEffect(() => {
    setVideoUrl(getMovieString());
  }, [valueData]);

  useEffect(() => {
    videoRef?.current?.load();
    // videoRef.current.play();
  }, [videoUrl]);

  return (
    <CardBox height={size[1] || 3}>
      <Card
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: 'primary.light',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent sx={{ height: '100%', padding: 0 }} ref={cardRef}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack direction="column">
              <Typography
                variant={isMobile ? 'body2' : 'subtitle1'}
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                color="primary.dark"
              >
                {dataAvailable(unitData, 'name') && unitData.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',

                  width: '100%',
                }}
              >
                <Stack direction="row">
                  <UpdateIcon fontSize="small" color="primary" />

                  <Typography variant="caption" sx={{ marginLeft: '0.5rem' }}>
                    {dataAvailable(valueData, 'time') &&
                      `${timestampToLocalDateTimeShort(valueData.time)}`}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              margin: 0,
              padding: 10,
              left: 0,
              top: 5,
              zIndex: 2,
              width: '100%',
              height: cardHeight,
            }}
          >
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                height: Math.min(cardWidth * (9 / 16), videoHeight),
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <InfoBox
                  title="FUEL CELL"
                  value={`${getValue('FuelCell--Vermogen')}${parseUnit(
                    'FuelCell--Vermogen'
                  )}`}
                  subValue1={`${getValue('FuelCell--Hpress')}${parseUnit(
                    'FuelCell--Hpress'
                  )}`}
                  subValue2={`${getValue('FuelCell--HT_TempOut')}${parseUnit(
                    'FuelCell--HT_TempOut'
                  )}`}
                  width={Math.round(cardWidth * 0.27)}
                  maxWidth={120}
                />
                <InfoBox
                  title="BATTERY"
                  value={`${getValue('Accu--SoC')}${parseUnit('Accu--SoC')}`}
                  subValue1={`${getValue('Accu--Vermogen')}${parseUnit(
                    'Accu--Vermogen'
                  )}`}
                  subValue2={`${getValue('Accu--Ugem')}${parseUnit(
                    'Accu--Ugem'
                  )}`}
                  width={Math.round(cardWidth * 0.27)}
                  maxWidth={120}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <InfoBox
                  title="AC IN"
                  value={`${getValue('OBC--Vermogen')}${parseUnit(
                    'OBC--Vermogen'
                  )}`}
                  subValue1={`${getValue('OBC--F1')}${parseUnit('OBC--F1')}`}
                  subValue2=""
                  width={Math.round(cardWidth * 0.27)}
                  maxWidth={120}
                />
                <InfoBox
                  title="AC OUT"
                  value={`${getValue('VectoPower--Power')}${parseUnit(
                    'VectoPower--Power'
                  )}`}
                  subValue1={`${getValue('VectoPower--F1')}${parseUnit(
                    'VectoPower--F1'
                  )}`}
                  subValue2={`${getValue('VectoPower--Hz')}${parseUnit(
                    'VectoPower--Hz'
                  )}`}
                  width={Math.round(cardWidth * 0.27)}
                  maxWidth={120}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StatusBox />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1.0rem',
              marginRight: '0.3rem',
            }}
          >
            <video
              onloadeddata="this.play();"
              width={Math.min(cardWidth, (videoHeight * 16) / 9) * 0.74}
              height={Math.min(cardWidth * (9 / 16), videoHeight) * 0.74}
              preload="auto"
              muted={true}
              autoPlay={true}
              loop={true}
              playsInline={true}
              ref={videoRef}
            >
              <source src={`/video/${getMovieString()}`} type="video/webm" />
            </video>
          </Box>
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const overviewCardObj = (position, size) => {
  const obj = {
    type: 'OverviewCard',
    typeLabel: `Overview Card ${size[0] / 3}x${size[1]}`,
    size,
    content: {},
    dataId: '',
    position,
  };
  return obj;
};
