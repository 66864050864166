import { useEffect, useContext, forwardRef } from 'react';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { AlarmContext } from '@/store/alarm-context';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function AlarmGeneral(props) {
  const { alarmData, showAlarm, clearAlarm } = useContext(AlarmContext);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    clearAlarm();
  };

  return (
    <Snackbar open={showAlarm} onClose={handleClose} sx={{ width: '90%' }}>
      <Alert
        onClose={handleClose}
        severity={alarmData.type || 'error'}
        sx={{ width: '90%' }}
      >
        {alarmData.msg || ''}
      </Alert>
    </Snackbar>
  );
}
