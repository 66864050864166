import Typography from '@mui/material/Typography';

export default function SimpleTable(props) {
  const { data } = props;

  const createTable = () => {
    return (
      <table>
        {data.map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <td key={j}>
                <Typography variant="caption" color="primary.dark">
                  {cell}
                </Typography>
              </td>
            ))}
          </tr>
        ))}
      </table>
    );
  };

  return createTable();
}
