import React from 'react';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import PaginationTable from './PaginationTable';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';

//data should have the format as follows [{},{},{}]   each object should have keys that correspond to the different columns

const ComplexTable = (props) => {
  const {
    data,
    columns,
    defaultPageSize = 50,
    toPaginate = true,
    showHeader = true,
    tableName = 'tableName',
    rowStyle = {},
    tableDivStyle = {},
    tableStyle = {},
  } = props;

  //initialize table columns and data with optionally pagination information
  let tableInput;
  // debugger;
  if (toPaginate) {
    tableInput = {
      columns,
      data,
      initialState: { pageSize: defaultPageSize },
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
    };
  } else {
    tableInput = {
      columns,
      data,
      autoResetPage: false,
    };
  }
  const tableFuncInput = [tableInput, useFilters, useGlobalFilter, useSortBy];
  if (toPaginate) tableFuncInput.push(usePagination);
  const tableInstance = useTable(...tableFuncInput);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  if (!data || !columns) return <></>;
  const tableStyling = {
    mb: 2,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    maxHeight: '75vh',
    overflow: 'scroll',

    // overflowY: "scroll",
    // overflowX: "scroll",
    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
  };
  const rowStyleClassName = `${tableName}__rowStyle`;
  const tableStyleClassName = `${tableName}__tableStyle`;
  if (!objEmptyOrUndefined(rowStyle))
    tableStyling[`.${rowStyleClassName}`] = rowStyle;
  if (!objEmptyOrUndefined(tableStyle))
    tableStyling[`.${tableStyleClassName}`] = tableStyle;

  return (
    <>
      <div style={tableDivStyle}>
        <Box sx={tableStyling}>
          <table {...getTableProps()} className={tableStyleClassName}>
            {showHeader && (
              <thead>
                {
                  // Loop over the header rows

                  headerGroups.map((headerGroup, hIdx) => (
                    // Apply the header row props
                    <tr
                      key={`hr-${hIdx}`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column, colIdx) => (
                          // Apply the header cell props
                          <th
                            key={`hr-${hIdx}-hc-${colIdx}`}
                            {...column.getHeaderProps([
                              {
                                className: column.headerClassName,
                                style: column.headerStyle,
                              },
                              column.getSortByToggleProps(),
                            ])}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                flexWrap: 'wrap',
                              }}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {column.canFilter && column.Filter
                                ? column.render('Filter')
                                : ''}
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
            )}

            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row, rIndex) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      key={`data-row-${rIndex}`}
                      className={rowStyleClassName}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell, cellIndex) => {
                          // Apply the cell props
                          return (
                            <td
                              key={`data-row-${rIndex}-cell-${cellIndex}`}
                              {...cell.getCellProps([
                                {
                                  className: cell.column.className,
                                  style: cell.column.bodyStyle,
                                },
                              ])}
                            >
                              {' '}
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </Box>
      </div>

      <div className="centralize centralize_content">
        <div className="mt-2" style={{ height: '1rem' }} />
        {toPaginate && (
          // <PaginationTable
          //   gotoPage={gotoPage}
          //   pageOptions={pageOptions}
          //   pageIndex={pageIndex}
          //   pageSize={pageSize}
          //   pageCount={pageCount}
          //   setPageSize={setPageSize}
          //   previousPage={previousPage}
          //   nextPage={nextPage}
          //   canNextPage={canNextPage}
          //   canPreviousPage={canPreviousPage}
          // />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <PaginationTable
              goToPage={gotoPage}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageSize={setPageSize}
              rowCount={data.length}
              pageCount={pageCount}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ComplexTable;
