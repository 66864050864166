import { useState, useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { DashboardContext } from '@/store/dashboard-context';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';

export default function StatusMenu(props) {
  const { value, dataId } = props;
  const { statusCodes } = useContext(DashboardContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (objEmptyOrUndefined(statusCodes)) return '';
  if (dataId == null || !(dataId in statusCodes)) return '';
  if (value == null || value == 'null') return '';

  let val;
  try {
    val = Number(value);
  } catch (error) {
    return '';
  }
  const statusses = [];
  for (const key in statusCodes[dataId]) {
    const bit = Number(key);
    if ((val & bit) == bit) {
      statusses.push(statusCodes[dataId][key].value);
    }
  }
  if (statusses.length == 0) return '';

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
      >
        <HelpOutlineIcon sx={{ fontSize: 14 }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {statusses.map((status, index) => (
          <MenuItem key={index} onClick={handleClose}>
            {status}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
