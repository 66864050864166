import React from 'react';
import Image from 'next/image';

import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Container from '@mui/material/Container';
import TypoGraphy from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const linksData = {
  links: [
    { label: 'News', url: 'https://watermeln.com/en/news#News-EN' },
    { label: 'Team', url: 'https://watermeln.com/en/about-us#Team-EN' },
  ],
  Technology: [
    {
      label: 'Technology',
      url: 'https://watermeln.com/en/technology#technology-EN',
    },
    {
      label: 'Watermeln 200',
      url: 'https://watermeln.com/en/technology#product-watermeln-200-EN',
    },
    {
      label: 'Hydrogen tanks',
      url: 'https://watermeln.com/en/technology#product-Hydrogen-tanks',
    },
    { label: 'FAQ', url: 'https://watermeln.com/en/technology#FAQ-EN' },
  ],
  Adres: [
    { label: 'Zekeringstraat 17A' },
    { label: '1014 BM Amsterdam' },
    { label: 'The Netherlands' },
  ],
  Bottom: [
    { label: '© Watermeln - All rights reserved' },
    { label: 'Privacy policy', url: 'https://watermeln.com/en/privacy-policy' },
    {
      label: 'Cookie statement',
      url: 'https://watermeln.com/en/cookie-statement',
    },
  ],
  Contact: [
    { label: '+31 (0) 20 705 89 70' },
    { label: 'info@watermeln.com ', url: 'mailto:info@watermeln.com' },
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/company/watermeln/',

      icon: (
        <LinkedInIcon
          sx={{
            color: 'secondary.contrastText',
            backgroundColor: 'secondary.dark',
            mr: 1,
          }}
          fontSize="small"
        />
      ),
    },
    {
      label: 'Whatsapp',
      url: 'https://wa.me/31620558970',
      icon: (
        <WhatsAppIcon
          fontSize="small"
          sx={{
            color: 'secondary.contrastText',
            backgroundColor: 'secondary.dark',
            mr: 1,
          }}
        />
      ),
    },
  ],
};

const generate = (data, keyPrefix, typVariant = 'body') => {
  const components = data.map((item, index) => {
    const typeProps = item.url ? { component: 'a', href: item.url } : {};
    return (
      <ListItemText
        key={keyPrefix + index}
        primary={
          <>
            {item.icon}
            <TypoGraphy
              variant={typVariant}
              color="secondary.contrastText2"
              sx={{
                '&:hover': { color: 'secondary.contrastText' },
                textDecoration: 'none',
              }}
              {...typeProps}
            >
              {item.label}
            </TypoGraphy>
          </>
        }
      />
    );
  });
  return components;
};

const Footer = (prop) => {
  return (
    <footer>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ backgroundColor: 'secondary.dark' }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffff80',
            width: '100%',
            pt: 4,
            pb: 4,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              justifyContent: { xs: 'center' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            <Toolbar
              sx={{ flexDirection: { xs: 'column', md: 'row' } }}
              disableGutters
            >
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <Image
                  src="/images/logo-watermeln.svg"
                  width={240}
                  height={50}
                  alt="Picture of the author"
                />
              </Box>

              <TypoGraphy variant="h5" color={'secondary.dark'}>
                The plug & play green hydrogen generator
              </TypoGraphy>
            </Toolbar>
          </Container>
        </Box>
        <Box
          sx={{
            width: '100%',
            pt: 4,
            pb: 4,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              justifyContent: { xs: 'center' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <TypoGraphy
                  variant="h8"
                  color={'secondary.contrastText'}
                  fontWeight={700}
                >
                  Links
                </TypoGraphy>
                <List>{generate(linksData.links, 'links-')}</List>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TypoGraphy
                  variant="h8"
                  color={'secondary.contrastText'}
                  fontWeight={700}
                >
                  Technology
                </TypoGraphy>
                <List>{generate(linksData.Technology, 'technology-')}</List>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TypoGraphy
                  variant="h8"
                  color={'secondary.contrastText'}
                  fontWeight={700}
                >
                  Adres
                </TypoGraphy>
                <List>{generate(linksData.Adres, 'adres-')}</List>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TypoGraphy
                  variant="h8"
                  color={'secondary.contrastText'}
                  fontWeight={700}
                >
                  Contact
                </TypoGraphy>
                <List>{generate(linksData.Contact, 'contact-')}</List>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: '#0000000f',
            width: '100%',
            pt: 1,
            pb: 1,
          }}
        >
          <Container maxWidth="xl">
            <Toolbar
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: { xs: 'center' },
                textAlign: { xs: 'center', sm: 'left' },
                mb: { xs: 5, sm: 2 },
              }}
              disableGutters
            >
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 0, sm: 3 },
                  }}
                >
                  {generate(linksData.Bottom, 'Bottom-', 'caption')}
                </List>
              </Box>

              <TypoGraphy
                variant="caption"
                color={'secondary.contrastText2'}
                component="a"
                sx={{
                  '&:hover': { color: 'secondary.contrastText' },
                  textDecoration: 'none',
                  mr: 1,
                }}
              >
                v1.38.3
              </TypoGraphy>

              <TypoGraphy
                variant="caption"
                color={'secondary.contrastText2'}
                component="a"
                href="https://www.linkedin.com/in/christopher-lynch-07727a2a/"
                sx={{
                  '&:hover': { color: 'secondary.contrastText' },
                  textDecoration: 'none',
                }}
              >
                website by Christopher Lynch
              </TypoGraphy>
            </Toolbar>
          </Container>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
