import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import postData from '@/utils/postData';
import { usePassword } from './loginFormValidations';
import { AuthContext } from '@/store/auth-context';
function ForgotPasswordReset(props) {
  //to close modal
  const { handleClose } = props;

  const { setFormToShow, openLoginModal, closeTriggerLogin, onLogin } =
    useContext(AuthContext);

  const router = useRouter();
  const resetToken = router.query.resetToken;
  //password states and validation logic
  const {
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isPasswordValid,
    passwordErrorMessage,
  } = usePassword();

  //state to hide and show password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //state to show error message
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');

  //to apply color
  const theme = useTheme();

  //function to remove error message
  useEffect(() => {
    if (isPasswordValid) {
      setOpenAlert(false);
    }
  }, [isPasswordValid]);

  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
      setMessage('');
      setPassword('');
      setPasswordConfirm('');
    }
  }, [openLoginModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password: password.trim(),
      passwordConfirm: passwordConfirm.trim(),
    };

    //check on validations
    if (!isPasswordValid || password.length === 0) {
      setOpenAlert(true);
      setMessage('Please correct all errors');
    } else {
      const [loginData, success, postMessage] = await postData(
        data,
        true,
        `/users/resetPassword/${resetToken}`
      );
      if (!success) {
        setOpenAlert(true);
        setMessage(postMessage);
      } else {
        closeTriggerLogin();
        await onLogin(loginData.userId, loginData.expireTime);
        setMessage('');
        setOpenAlert(false);
        router.replace('/');
        handleClose();
      }
    }
  };

  return (
    <>
      <FormGroup>
        <>
          <div>
            <Typography
              variant="h6"
              color="secondary.contrastText"
              sx={{ mb: 1, fontWeight: 'bold' }}
            >
              Reset your password
            </Typography>
          </div>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              '&.Mui-disabled': {
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
              },
              width: '100%',
            }}
            onClick={() => setFormToShow('login')}
          >
            {'<Back'}
          </Button>

          <TextField
            id="password"
            label="Password"
            name="password"
            variant="outlined"
            sx={{ mt: 1 }}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            error={
              (password.length > 0 && !isPasswordValid) ||
              (openAlert && password.length === 0)
            }
            helperText={
              (password.length > 0 && !isPasswordValid
                ? passwordErrorMessage
                : '') +
              (openAlert && password.length === 0
                ? 'please provide a password'
                : '')
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
          <TextField
            id="passwordConfirm"
            label="Password confirm"
            variant="outlined"
            sx={{ mt: 1 }}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            error={passwordConfirm.length > 0 && !isPasswordValid}
            helperText={
              passwordConfirm.length > 0 && !isPasswordValid
                ? passwordErrorMessage
                : ''
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 1, width: '100%' }}
            onSubmit={handleSubmit}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          {openAlert && <Alert severity="error">{message}</Alert>}
        </>
      </FormGroup>
    </>
  );
}
export default ForgotPasswordReset;
