import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import useIsMobile from '@/hooks/useIsMobile';
import { calcHeight } from './utils/calcHeight';

export default function CardBox(props) {
  const { height } = props;

  const isMobilePadding = useIsMobile('lg');
  const sxProps = {
    width: '100%',
    height: calcHeight(height),
    // height: `${height * standardHeight}rem`,
    padding: isMobilePadding ? '0.2rem' : '0.5rem',
  };
  if (isMobilePadding) {
    sxProps.marginBottom = '0.5rem';
  }
  return <Box sx={sxProps}>{props.children}</Box>;
}
