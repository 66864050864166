import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import postData from '@/utils/postData';
import { useEmail, useFirstName, usePassword } from './loginFormValidations';
import { AuthContext } from '@/store/auth-context';
import Link from 'next/link';
function RegisterForm(props) {
  const { initialEmail, showButtonGroup = true } = props;
  //property that sets whether to show register or login

  const { setFormToShow, openLoginModal } = useContext(AuthContext);

  //email state and validation logic
  const { email, setEmail, isEmailValid } = useEmail();

  //first and last name state and validation logic
  const { firstName, setFirstName, isFirstNameValid } = useFirstName();
  const {
    firstName: lastName,
    setFirstName: setLastName,
    isFirstNameValid: isLastNameValid,
  } = useFirstName();

  //password states and validation logic
  const {
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isPasswordValid,
    passwordErrorMessage,
  } = usePassword();

  //state to hide and show password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //state to show error message
  const [openAlert, setOpenAlert] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [registerationComplete, setRegisterationComplete] = useState(false);

  //to apply color
  const theme = useTheme();

  //function to remove error message
  useEffect(() => {
    if (
      isEmailValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isPasswordValid
    ) {
      setOpenAlert(false);
    }
  }, [isEmailValid, isFirstNameValid, isLastNameValid, isPasswordValid]);

  useEffect(() => {
    if (initialEmail && initialEmail !== '') {
      setEmail(initialEmail);
    }
    console.log('initial email: ', initialEmail);
  }, [initialEmail]);
  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
      setMessage('');
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
      setFirstName('');
      setLastName('');
    }
  }, [openLoginModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email.trim(),
      password: password.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      passwordConfirm: passwordConfirm.trim(),
      acceptedTerms: checked,
    };
    if (!checked) {
      setOpenAlert(true);
      setMessage('Please accept terms and conditions');
      return;
    }

    //check on validations
    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isPasswordValid ||
      email.length === 0 ||
      firstName.length === 0 ||
      lastName.length === 0 ||
      password.length === 0
    ) {
      setOpenAlert(true);
      setMessage('Please correct all errors');
    } else {
      const [returnData, success, postMessage] = await postData(
        data,
        false,
        '/users/signup'
      );
      if (!success) {
        setOpenAlert(true);
        setMessage(postMessage);
      } else {
        setRegisterationComplete(true);
      }
    }
  };

  return (
    <>
      <FormGroup>
        {showButtonGroup && (
          <ButtonGroup sx={{ mt: 1, width: '100%' }}>
            <Button
              color="secondary"
              variant="outlined"
              disabled
              sx={{
                '&.Mui-disabled': {
                  background: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                  width: '50%',
                },
              }}
            >
              Register
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{ width: '50%' }}
              onClick={() => setFormToShow('login')}
            >
              Login
            </Button>
          </ButtonGroup>
        )}
        {registerationComplete ? (
          <Alert severity="success">
            {initialEmail
              ? 'Your registration is complete, please proceed to login.'
              : 'Please validate your email, you received a confirmation email.'}
          </Alert>
        ) : (
          <>
            <TextField
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              variant="outlined"
              sx={{ mt: 1 }}
              onChange={(e) => setEmail(e.target.value)}
              error={
                (email.length > 0 && !isEmailValid) ||
                (openAlert && email.length === 0)
              }
              helperText={
                (email.length > 0 && !isEmailValid
                  ? 'please provide a valid email'
                  : '') +
                (openAlert && email.length === 0
                  ? 'please provide an email'
                  : '')
              }
            />

            <TextField
              id="firstName"
              label="First name"
              variant="outlined"
              name="firstName"
              autoComplete="given-name"
              sx={{ mt: 1 }}
              onChange={(e) => setFirstName(e.target.value)}
              error={
                (firstName.length > 0 && !isFirstNameValid) ||
                (openAlert && firstName.length === 0)
              }
              helperText={
                (firstName.length > 0 && !isFirstNameValid
                  ? 'Forbidden name (can cause confusion with website owner)'
                  : '') +
                (openAlert && firstName.length === 0
                  ? 'please provide a first name'
                  : '')
              }
              InputProps={{ type: 'email', autoComplete: 'email' }}
            />
            <TextField
              id="lastName"
              label="Last name"
              name="lastName"
              autoComplete="family-name"
              variant="outlined"
              sx={{ mt: 1 }}
              onChange={(e) => setLastName(e.target.value)}
              error={
                (lastName.length > 0 && !isLastNameValid) ||
                (openAlert && lastName.length === 0)
              }
              helperText={
                (lastName.length > 0 && !isLastNameValid
                  ? 'Forbidden name (can cause confusion with website owner)'
                  : '') +
                (openAlert && lastName.length === 0
                  ? 'please provide a last name'
                  : '')
              }
            />
            <TextField
              id="password"
              label="Password"
              name="password"
              variant="outlined"
              sx={{ mt: 1 }}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              error={
                (password.length > 0 && !isPasswordValid) ||
                (openAlert && password.length === 0)
              }
              helperText={
                (password.length > 0 && !isPasswordValid
                  ? passwordErrorMessage
                  : '') +
                (openAlert && password.length === 0
                  ? 'please provide a password'
                  : '')
              }
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            <TextField
              id="passwordConfirm"
              label="Password confirm"
              variant="outlined"
              sx={{ mt: 1 }}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              error={passwordConfirm.length > 0 && !isPasswordValid}
              helperText={
                passwordConfirm.length > 0 && !isPasswordValid
                  ? passwordErrorMessage
                  : ''
              }
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            <Box sx={{ mt: 1, display: 'flex' }}>
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography sx={{ mt: 2 }} variant="body2">
                By pressing submit you agree with our{' '}
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1_NmT7kJcUFl_ZG0fY5smMk8lbUjG6Tbs/view"
                >
                  general terms and conditions
                </a>{' '}
                and acknowledge reading our{' '}
                <a
                  target="_blank"
                  href="https://watermeln.com/en/privacy-policy"
                >
                  privacy policy
                </a>
              </Typography>

              <Typography></Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 1, width: '100%' }}
              onSubmit={handleSubmit}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {openAlert && <Alert severity="error">{message}</Alert>}
          </>
        )}
      </FormGroup>
    </>
  );
}
export default RegisterForm;
