import { useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Tooltip from '@mui/material/Tooltip';
import SelectionMenuWrapper from '@/components/utils/SelectionMenuWrapper';
import { DashboardContext } from '@/store/dashboard-context';
import EmptyCard from '../EmptyCard';
import { AlarmContext } from '@/store/alarm-context';
import { AuthContext } from '@/store/auth-context';
import { getCardOptions } from '../utils/cardLists';
export default function ChangeCardType(props) {
  const { item } = props;
  const { changeCardType, getItem } = useContext(DashboardContext);
  const { restrictTo } = useContext(AuthContext);
  const { setAlarmData } = useContext(AlarmContext);

  const cardOptions = getCardOptions(item);

  if (!restrictTo(['admin', 'owner_admin'])) {
    delete cardOptions['Command Card 2x1'];
    delete cardOptions['Usage Card 2x1'];
  }
  if (restrictTo(['public'])) {
    delete cardOptions['Fuel Card 2x1'];
    delete cardOptions['Map Card 4x3'];
    delete cardOptions['Map Card 4x2'];
    delete cardOptions['Map Card 4x1'];
    delete cardOptions['Alarm Card 2x1'];
  }

  const onChange = (newItem) => {
    if (newItem === '' || newItem == null) return;
    //for smaller cards we need nested cards and additional checks are required
    if (cardOptions[newItem].size[0] < 12) {
      //check if we already have a nested parent card
      const parentItem = getItem([item.position[3][0]]);
      console.log('parentItem', parentItem);
      //if we already have a nested parent card we need to check if there is sufficient room available
      if (parentItem.type == 'NestedCard' && item.position[3].length > 1) {
        //check the used space excluding empty cards
        let sizeUsed = 0;
        parentItem.content.forEach((card) => {
          if (card.position !== item.position && card.type != 'EmptyCard') {
            sizeUsed += card.size[0];
          }
        });

        if (sizeUsed + cardOptions[newItem].size[0] > 12) {
          //we must alert the user that there is not enough space
          setAlarmData({
            type: 'error',
            msg: 'Not enough space to fit requested card',
            duration: 6000,
            category: 'cardDoesNotFitError',
          });
          return;
        } else {
          //the requested card fits the available space
          //check if not all nested cards are empty
          const nonEmpty = parentItem.content.filter((card) => {
            if (card.type == 'EmptyCard') return false;
            if (card.position == item.position) return false;
            return true;
          });
          if (
            nonEmpty.length == 0 &&
            cardOptions[newItem].type[0] == 'EmptyCard'
          ) {
            //all content is empty, we can create an empty card instead of a nested card
            return changeCardType(
              ['EmptyCard'],
              [[parentItem.position, [12, 1]]],
              parentItem.position
            );
          }
          //if not all nested cards are empty we can replace the card with the requested card
          return changeCardType(
            cardOptions[newItem].type,
            cardOptions[newItem].inputs,
            item.position
          );
        }
      } else {
        //no nested card available, we create both a nested card and the requested card, and fill remaining space with an empty card
        const createType = [
          'NestedCard',
          ...cardOptions[newItem].type,
          'EmptyCard',
        ];
        //the new nested position can take over the complete position of the old item (including the other column definitions)
        const createInputs = [
          //the parent item
          [item.position, [12, 1]],
          //the requested card
          [{ 3: [item.position[3][0], 0] }, cardOptions[newItem].size],
          //empty card
          [
            { 3: [item.position[3][0], 1] },
            [12 - cardOptions[newItem].size[0], 1],
          ],
        ];
        //the parent item is not a nested item, this implies that the position of the new item must have an additional level
        //it will be the first item in the nested card so we need to add 0 to the position

        return changeCardType(createType, createInputs, item.position);
      }
    } else {
      //check if this is a nested card, if so we must check if no further items are there
      console.log('item.position[3].length', item.position[3].length);
      if (item.position[3].length > 1) {
        const parentCard = getItem([item.position[3][0]]);
        //count non empty cards excluding the current card
        const nonEmpty = parentCard.content.filter((card) => {
          if (card.type == 'EmptyCard') return false;
          if (card.position == item.position) return false;
          return true;
        });
        if (nonEmpty.length != 0) {
          // #ADD alarm
          setAlarmData({
            type: 'error',
            msg: 'There is no room to place this card, please remove highlighted card before replacing this card. Alternatively change the parent card type.',
            duration: 8000,
            category: 'cardNonEmptyError',
            data: { nonEmpty },
          });
          return;
        }
      }
      //
      return changeCardType(
        cardOptions[newItem].type,
        cardOptions[newItem].inputs,
        item.position
      );
    }
  };

  return (
    <SelectionMenuWrapper
      buttonProps={{ size: 'small', sx: { margin: 0, padding: 0 } }}
      value={item.typeLabel}
      options={cardOptions}
      onChange={onChange}
      additionalArgsOnChange={[item.position[3]]}
      labelIdentifier="typeLabel"
      menuId={`valueMenu-${item.position[3].toString()}`}
      autocompleteProps={{ sx: { width: '15rem' } }}
      onHover={false}
    >
      <Tooltip title={'Change card type.'} placement="top">
        <ChangeCircleIcon fontSize="small" />
      </Tooltip>
    </SelectionMenuWrapper>
  );
}
