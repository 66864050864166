import { useState, useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from '@mui/material';
import LoginForm from './LoginForm';

import postData from '@/utils/postData';
import { AuthContext } from '@/store/auth-context';
import RegisterForm from './RegisterForm';

// #REMOVE most likely this function can be depreciated
function HandleQR(props) {
  //get dynamic path
  const router = useRouter();
  const qrToken = router.query.qrToken;

  //messaging state
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('error');
  const [showLogin, setShowLogin] = useState(true);

  //check if validation has been send:
  const effectCalled = useRef(false);
  //context
  const { setFormToShow, openLoginModal, isLoggedIn } = useContext(AuthContext);
  //to apply color
  const theme = useTheme();
  console.log('test');
  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
    }
  }, [openLoginModal]);

  useEffect(() => {
    if (!effectCalled.current && isLoggedIn) {
      effectCalled.current = true;
      setIsValidated(false);
      handleSubmit();
    }
  }, [isLoggedIn]);
  //submit form to backend for login
  //check for email and password
  //message on error and close on succes
  const handleSubmit = async () => {
    //check on validations

    const [data, success, postMessage] = await postData(
      null,
      true,
      `/qr/${qrToken}`
    );
    if (!success) {
      setAlertType('error');
      setOpenAlert(true);
      setMessage(postMessage);
    } else {
      setAlertType('success');
      setMessage(postMessage);
      setOpenAlert(true);
      setTimeout(() => {
        setIsValidated(true);
      }, 6000);
      router.replace('/');
    }
  };

  return (
    <>
      <FormGroup>
        {!isLoggedIn && (
          <>
            <Typography
              variant="h6"
              color="primary.dark"
              sx={{ mb: 1, fontWeight: 'bold' }}
            >
              Add unit by QR code
            </Typography>
            <Typography variant="body2" color="primary.dark" sx={{ mb: 1 }}>
              {`You must be logged in to add a unit with this QR code. Please log
              in. If you don't have an account yet, you can register and try
              again.`}
            </Typography>

            {showLogin ? (
              <>
                <ButtonGroup sx={{ mt: 1, width: '100%' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                      width: '50%',
                    }}
                    onClick={() => setShowLogin(false)}
                  >
                    Register
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled
                    sx={{
                      '&.Mui-disabled': {
                        background: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                      },
                      width: '50%',
                    }}
                  >
                    Login
                  </Button>
                </ButtonGroup>
                <LoginForm showButtonGroup={false} />
              </>
            ) : (
              <>
                <ButtonGroup sx={{ mt: 1, width: '100%' }}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled
                    sx={{
                      '&.Mui-disabled': {
                        background: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        width: '50%',
                      },
                    }}
                  >
                    Register
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ width: '50%' }}
                    onClick={() => setShowLogin(true)}
                  >
                    Login
                  </Button>
                </ButtonGroup>
                <RegisterForm showButtonGroup={false} />
              </>
            )}
          </>
        )}
        {isLoggedIn && ''}
      </FormGroup>
    </>
  );
}
export default HandleQR;
