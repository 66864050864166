import { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material';

import postData from '@/utils/postData';
import { useEmail, useFirstName, usePassword } from './loginFormValidations';
import RegularSelect from '@/components/utils/RegularSelect';
import { AuthContext } from '@/store/auth-context';
import fetchData from '@/utils/fetchData';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;
function UpdateProfile(props) {
  //property that sets whether to show register or login

  const { setFormToShow, openLoginModal } = useContext(AuthContext);

  //email state and validation logic
  const { email, setEmail, isEmailValid } = useEmail();

  //first and last name state and validation logic
  const { firstName, setFirstName, isFirstNameValid } = useFirstName();
  const {
    firstName: lastName,
    setFirstName: setLastName,
    isFirstNameValid: isLastNameValid,
  } = useFirstName();

  //state to show error message
  const [originalData, setOriginalData] = useState({});

  const [conditionalEmailProps, setConditionalEmailProps] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [region, setRegion] = useState('NL');
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneVerifying, setPhoneVerifying] = useState(false);
  const [timer, setTimer] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
    location: 'updateProfile',
  });
  //to apply color
  const theme = useTheme();

  const regionOptions = useMemo(() => {
    const supportedRegions = phoneUtil.getSupportedRegions();
    const regionOptions = [];
    supportedRegions.forEach((sRegion) => {
      const obj = {
        key: sRegion,
        label: `${sRegion} +${phoneUtil.getCountryCodeForRegion(sRegion)}`,
      };
      regionOptions.push(obj);
    });

    return regionOptions;
  }, []);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: 'success',
      location: 'updateProfile',
    });
  };

  //show alert and close alert after timeout
  const handleAlert = (alertObj, duration) => {
    setAlert(alertObj);
    setTimeout(() => {
      resetAlert();
    }, duration * 1000);
  };

  //load profile on startup
  const getProfileData = async () => {
    if (openLoginModal) {
      //get data from api
      const [returnData, success, message] = await fetchData(
        '/users/me',
        'doc'
      );
      //set state
      if (success) {
        setEmail(returnData.email);
        setFirstName(returnData.firstName);
        setLastName(returnData.lastName);
        setPhoneNumber(returnData.phoneNumber || '');
        setRegion(returnData.region || 'NL');
        setOriginalData(returnData);
      }
    }
  };
  useEffect(() => {
    getProfileData();
  }, [openLoginModal]);

  //check if original data changes. If so compare email and emailChanged.
  //if the two are not the same add conditional props to email input
  useEffect(() => {
    if (!objEmptyOrUndefined(originalData)) {
      if (
        originalData.emailChange &&
        originalData.emailChange !== originalData.email
      ) {
        setConditionalEmailProps({
          InputProps: {
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    'Your requested an update of your email, but it has not yet been verified. Please verify your new email to see changes.'
                  }
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{ fontSize: 13, verticalAlign: 'top' }}
                  />
                </Tooltip>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          },
        });
      } else {
        setConditionalEmailProps({});
      }
    }
  }, [originalData]);
  useEffect;

  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      resetAlert();
      setEmail('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setRegion('NL');
    }
  }, [openLoginModal]);

  const isPhoneValid = (phoneCheck, regionCheck) => {
    let isValid = false;
    try {
      isValid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(phoneCheck, regionCheck),
        regionCheck
      );
    } catch (e) {
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };

    //check on validations
    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      email.length === 0 ||
      firstName.length < 3 ||
      lastName.length < 3
    ) {
      const alertObj = {
        open: true,
        message: 'Please correct all errors',
        severity: 'error',
        location: 'profileUpdate',
      };
      handleAlert(alertObj, 8);
    } else {
      //only send data that has changed
      ['email', 'firstName', 'lastName'].forEach((key) => {
        if (data[key] === originalData[key]) {
          delete data[key];
        }
      });

      if (objEmptyOrUndefined(data)) {
        const alertObj = {
          open: true,
          message: 'No changes were made',
          severity: 'info',
          location: 'profileUpdate',
        };
        handleAlert(alertObj, 8);

        return;
      }

      const [returnData, success, postMessage] = await postData(
        data,
        true,
        '/users/updateMe'
      );
      if (!success) {
        const alertObj = {
          open: true,
          message: postMessage,
          severity: 'error',
          location: 'profileUpdate',
        };
        handleAlert(alertObj, 10);
      } else {
        const alertObj = {
          open: true,
          message: postMessage,
          severity: 'success',
          location: 'profileUpdate',
        };
        handleAlert(alertObj, 20);
        await getProfileData();
      }
    }
  };

  function countdown() {
    setTimer(15);
    let count = 15;
    const intervalId = setInterval(() => {
      count--;
      setTimer(count);
      if (count <= 0) {
        console.log('interval cleared', timer);
        clearInterval(intervalId);
        setTimer(0);
      }
    }, 1000);
  }
  const handleVerifyNumber = async () => {
    setPhoneVerifying(true);
    const [returnData, success, postMessage] = await postData(
      {
        phone: phoneNumber,
        region,
      },
      true,
      '/users/updatePhone'
    );
    countdown();
  };

  const handleVerifyCode = async () => {
    const [returnData, success, postMessage] = await postData(
      {
        code: verificationCode,
      },
      true,
      '/users/verifyPhone'
    );
    if (success) {
      const alertObj = {
        open: true,
        message: postMessage,
        severity: 'success',
        location: 'phoneUpdate',
      };
      handleAlert(alertObj, 8);
      setPhoneVerifying(false);
    } else {
      const alertObj = {
        open: true,
        message: postMessage,
        severity: 'error',
        location: 'phoneUpdate',
      };
      handleAlert(alertObj, 8);
    }
  };

  return (
    <>
      <FormGroup>
        <>
          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mb: 1, fontWeight: 'bold' }}
          >
            Update profile
          </Typography>
          <TextField
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            variant="outlined"
            sx={{ mt: 1 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={
              (email.length > 0 && !isEmailValid) ||
              (alert.open &&
                alert.location === 'profileUpdate' &&
                email.length === 0)
            }
            helperText={
              (email.length > 0 && !isEmailValid
                ? 'please provide a valid email'
                : '') +
              (alert.open &&
              alert.location === 'profileUpdate' &&
              email.length === 0
                ? 'please provide an email'
                : '')
            }
            {...conditionalEmailProps}
          />

          <TextField
            id="firstName"
            label="First name"
            variant="outlined"
            name="firstName"
            autoComplete="given-name"
            sx={{ mt: 1 }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={
              (firstName.length > 0 && !isFirstNameValid) ||
              (alert.open &&
                alert.location === 'profileUpdate' &&
                firstName.length < 3)
            }
            helperText={
              (firstName.length > 0 && !isFirstNameValid
                ? 'Forbidden name (can cause confusion with website owner)'
                : '') +
              (alert.open &&
              alert.location === 'profileUpdate' &&
              firstName.length < 3
                ? 'please provide a first name'
                : '')
            }
            InputProps={{ type: 'email', autoComplete: 'email' }}
          />
          <TextField
            id="lastName"
            label="Last name"
            name="lastName"
            autoComplete="family-name"
            variant="outlined"
            sx={{ mt: 1 }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={
              (lastName.length > 0 && !isLastNameValid) ||
              (alert.open &&
                alert.location === 'profileUpdate' &&
                lastName.length < 3)
            }
            helperText={
              (lastName.length > 0 && !isLastNameValid
                ? 'Forbidden name (can cause confusion with website owner)'
                : '') +
              (alert.open &&
              alert.location === 'profileUpdate' &&
              lastName.length < 3
                ? 'please provide a last name'
                : '')
            }
          />

          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 1, width: '100%' }}
            onSubmit={handleSubmit}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          {alert.open && alert.location === 'profileUpdate' && (
            <Alert severity={alert.severity}>{alert.message}</Alert>
          )}
          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}
          >
            Update phone number
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <RegularSelect
              value={
                region == null || ''
                  ? ''
                  : regionOptions.map((option) => option.key).indexOf(region)
              }
              options={regionOptions}
              handleChange={(e) => setRegion(regionOptions[e.target.value].key)}
              keyVal="regionSelect"
              renderOption={(option) => option.label}
              label="Region"
              custom={false}
              sxProps={{ mt: 1, width: '35%' }}
              //   borderColor={'alarm.ok'}
              textFieldProps={{
                key: 'regionSelect-text',
                required: true,
                disabled: phoneVerifying,
              }}
            />
            <TextField
              id="phoneNumber"
              label="Phone number"
              name="phone"
              autoComplete="phone"
              variant="outlined"
              disabled={phoneVerifying}
              sx={{ mt: 1, width: '65%' }}
              value={phoneNumber}
              onChange={(e) =>
                setPhoneNumber(e.target.value.replace(/[^0-9+]/g, ''))
              }
              error={
                phoneNumber.length > 3 && !isPhoneValid(phoneNumber, region)
              }
            />
          </Box>
          {phoneVerifying ? (
            <>
              <TextField
                id="verificationCode"
                label="Verification code"
                name="verificationCode"
                variant="outlined"
                sx={{ mt: 1, width: '100%' }}
                value={verificationCode}
                onChange={(e) =>
                  setVerificationCode(e.target.value.replace(/[^0-9+]/g, ''))
                }
                error={
                  phoneNumber.length > 3 && !isPhoneValid(phoneNumber, region)
                }
              />
              <Box sx={{ display: 'flex' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{ mt: 1, width: '50%' }}
                  disabled={timer > 0}
                  onClick={() => handleVerifyNumber()}
                >
                  {`Resend code ${timer > 0 ? `(${timer})` : ''}`}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 1, width: '50%' }}
                  onClick={() => handleVerifyCode()}
                >
                  Verify code
                </Button>
              </Box>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 1, width: '100%' }}
              onClick={() => handleVerifyNumber()}
            >
              Verify number
            </Button>
          )}
          {alert.open && alert.location === 'phoneUpdate' && (
            <Alert severity={alert.severity}>{alert.message}</Alert>
          )}

          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}
          >
            Update security
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 1, width: '100%' }}
            onClick={() => setFormToShow('updatePassword')}
          >
            Update password
          </Button>
        </>
      </FormGroup>
    </>
  );
}
export default UpdateProfile;
