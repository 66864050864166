import { useContext, useEffect, useState } from 'react';

import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardBox from './CardBox';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import TransparentLayer from './customization/TransparentLayer';
import CardCustomization from './customization/CardCustomization';
import { calcHeightPadding } from './utils/calcHeight';
import { waterMeln3, waterMeln2 } from './utils/mapStyles';
import useIsMobile from '@/hooks/useIsMobile';
import { DashboardContext } from '@/store/dashboard-context';
import { AuthContext } from '@/store/auth-context';
import { dataAvailable } from './utils/dataAvailable';
import fetchData from '@/utils/fetchData';
import MapMarker from '../utils/MapMarker';
import RenderLoading from './utils/RenderLoading';
const libraries = ['places'];
export default function MapCard(props) {
  const { item } = props;
  const { content, dataId, size } = item;
  const { valueData, editMode, dataLoading } = useContext(DashboardContext);
  const { isLoggedIn } = useContext(AuthContext);
  const isMobilePadding = useIsMobile('lg');
  const [apiKey, setApiKey] = useState(null);
  const [center, setCenter] = useState({
    lng: 0,
    lat: 0,
  });
  // const map = useMap();

  useEffect(() => {
    const loadApiKey = async () => {
      const [data, success, errorMessage] = await fetchData('/maps');
      if (success) {
        setApiKey(data);
      }
    };

    if (isLoggedIn) {
      loadApiKey();
    }
  }, [isLoggedIn]);

  let additionalGraphics = {};
  if (editMode) additionalGraphics = { background: 'rgba(255, 255, 255, 0.5)' };

  const getValue = () => {
    if (!dataAvailable(valueData, dataId)) return null;
    if (!('coordinates' in valueData[dataId])) return null;

    return true;
  };

  useEffect(() => {
    const updateValue = () => {
      if (!dataAvailable(valueData, dataId)) return null;

      if (!('coordinates' in valueData[dataId])) return;
      if (
        valueData[dataId].coordinates[0] == null ||
        valueData[dataId].coordinates[1] == null
      )
        return;

      if (
        valueData[dataId].coordinates[0] !== center.lng ||
        valueData[dataId].coordinates[1] !== center.lat
      ) {
        const centerTemp = {
          lng: valueData[dataId].coordinates[0],
          lat: valueData[dataId].coordinates[1],
        };
        setCenter(centerTemp);
        // map.setCenter(centerTemp);
      }
    };
    updateValue();
  }, [valueData]);

  const showLoadText = () => {
    if (apiKey == null) return 'loading...';
    if (getValue() == null) return 'no location data available...';
  };

  const icon = {
    url: '/images/favicon/favicon-32x32.png', // url
    // scaledSize: new map.Size(24, 24), // scaled size
    // origin: new map.Point(0, 0), // origin
    // anchor: new map.Point(0, 0), // anchor
  };

  return (
    <CardBox height={size[1] || 3}>
      <Card sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent sx={{ height: '100%', padding: 0 }}>
          {dataLoading.value ? (
            <RenderLoading />
          ) : (
            <>
              {getValue() == null || apiKey == null ? (
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                    height: '90%',
                    display: 'flex',
                  }}
                >
                  {showLoadText()}
                </Typography>
              ) : (
                <div>
                  <APIProvider apiKey={apiKey}>
                    <MapMarker
                      center={center}
                      height={calcHeightPadding(
                        size[1] || 3,
                        isMobilePadding,
                        '0.5rem'
                      )}
                      styles={waterMeln3}
                    />
                  </APIProvider>
                </div>
              )}
            </>
          )}

          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const mapCardObj = (position, size) => {
  const obj = {
    type: 'MapCard',
    typeLabel: `Map Card ${size[0]}x${size[1]}`,
    content: ['Map Card Loading...'],
    dataId: 'Station--pos',
    size,
    position,
  };
  return obj;
};
