import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ForwardIcon from '@mui/icons-material/Forward';
import { DashboardContext } from '@/store/dashboard-context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export default function MoveCard(props) {
  const { item, buttonProps = {} } = props;
  const { moveCard } = useContext(DashboardContext);
  let columnNumber = 1;
  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.up('md'))) columnNumber = 2;
  if (useMediaQuery(theme.breakpoints.up('lg'))) columnNumber = 3;

  const handleClick = (left) => () => {
    moveCard(item.position, left, columnNumber);
  };

  return (
    <>
      <Tooltip title={'Move card a position back'} placement="top">
        <IconButton onClick={handleClick(true)} {...buttonProps}>
          <ForwardIcon fontSize="small" sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Move card a position forward'} placement="top">
        <IconButton onClick={handleClick(false)} {...buttonProps}>
          <ForwardIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}
