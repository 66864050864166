import { useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Box from '@mui/material/Box';
import ChangeCardType from './ChangeCardType';
import RemoveCard from './RemoveCard';
import MoveCard from './MoveCard';
import AddCard from './AddCard';
export default function CardCustomization(props) {
  const { item } = props;

  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        margin: 0,
        padding: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <AddCard
        item={item}
        buttonProps={{
          size: 'small',
          sx: { margin: 0, padding: 0, display: 'inline-flex' },
        }}
      />
      <MoveCard
        item={item}
        buttonProps={{
          size: 'small',
          sx: { margin: 0, padding: 0, display: 'inline-flex' },
        }}
      />
      <RemoveCard
        item={item}
        buttonProps={{
          size: 'small',
          sx: { margin: 0, padding: 0, display: 'inline-flex' },
        }}
      />
      <ChangeCardType item={item} />
    </Box>
  );
}
