import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
const MenuButtonActive = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.light,
  fontWeight: 700,
  borderRadius: 10,
  '&:hover': {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
}));
export default MenuButtonActive;
