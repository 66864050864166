import React, { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import AppBar from '@mui/material/AppBar';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Login from './Login/Login';
import DrawerComponent from './HeaderDrawer';
import HeaderButtonList from './HeaderButtonList';
import { AuthContext } from '@/store/auth-context';
//should be transformed using: https://mui.com/material-ui/react-app-bar/

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <section>
      <AppBar
        position="fixed"
        elevation={0}
        color="appBar"
        sx={{ backdropFilter: 'blur(5px)' }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ mt: 4 }} disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Link href={'https://watermeln.com/'}>
                <Image
                  src="/images/logo-watermeln.svg"
                  width={isMobile ? 160 : 240}
                  height={isMobile ? 33 : 50}
                  alt="Picture of the author"
                />
              </Link>
            </Box>
            {isMobile ? (
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid xs="auto" container>
                  <Grid md="auto">
                    <Login isMobile={isMobile} />
                  </Grid>
                </Grid>
                <Grid xs="auto">
                  <DrawerComponent />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
                sx={{}}
                alignItems="center"
              >
                <Grid md="auto">
                  {isLoggedIn && <HeaderButtonList isMobile={isMobile} />}
                </Grid>

                <Grid md="auto" container>
                  <Grid md="auto">
                    <Login isMobile={isMobile} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar sx={{ mt: 4 }} />
    </section>
  );
}
export default Header;
