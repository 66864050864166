import { createContext, useEffect, useState } from 'react';

export const AlarmContext = createContext({
  setAlarmData: () => {},
  clearAlarm: () => {},
  alarmData: { category: '', msg: '', duration: 0, data: {}, type: '' },
  showAlarm: false,
});

const AlarmContextProvider = ({ children }) => {
  const [alarmData, setAlarm] = useState({});
  const [showAlarm, setShowAlarm] = useState(false);

  const setAlarmData = (data) => {
    if (
      !('category' in data) ||
      !('msg' in data) ||
      !('duration' in data) ||
      !('type' in data)
    )
      throw new Error('invalid data');

    setAlarm(data);
    setShowAlarm(true);
    setTimeout(clearAlarm, data.duration);
  };

  const clearAlarm = () => {
    setShowAlarm(false);
    //small delay to prevent users from seeing a red flash
    setTimeout(setAlarm({}), 500);
  };

  return (
    <AlarmContext.Provider
      value={{
        setAlarmData,
        clearAlarm,
        alarmData,
        showAlarm,
      }}
    >
      {children}
    </AlarmContext.Provider>
  );
};

export default AlarmContextProvider;
