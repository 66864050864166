import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { Children } from 'react';

//import assert
import assert from 'assert';

// this function is used to switch link class based on router active
const ActiveLinkFunction = ({
  children,
  matchExact = false,
  matchRoot = false,
  matchAlt = '',
  ...props
}) => {
  /**
   * Generates a function comment for the given function body.
   *
   * @param {Object} props - The props passed to the component.
   * @param {ReactNode} props.children - The children of the component.
   * @param {string} props.href - The href of the link.
   * @param {string} props.as - An alias for href that will be used for the router's internal routing purposes.
   * @return {ReactNode} The modified link component.
   */
  const { asPath } = useRouter();
  const {} = props;
  assert(typeof children === 'function');
  let isActive = false;

  const matcher = matchAlt === '' ? props.href : matchAlt;

  if (matchExact) {
    isActive = asPath === matcher || asPath === props.as;
  } else if (matchRoot) {
    isActive =
      asPath === matcher ||
      asPath === props.as ||
      (asPath.startsWith(matcher) && matcher !== '/');
  } else {
    isActive =
      asPath === matcher ||
      asPath === props.as ||
      (asPath.includes(matcher) && matcher !== '/');
  }

  return <Link {...props}>{children({ isActive })}</Link>;
};

ActiveLinkFunction.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ActiveLinkFunction;
