import React, { useContext } from 'react';
import ActiveLinkFunction from '@/components/utils/ActiveLinkFunction';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { AuthContext } from '@/store/auth-context';
import MenuButton from './MenuButton';
import MenuButtonActive from './MenuButtonActive';
function HeaderButtonList({ onClickFunc = () => {}, ...props }) {
  const theme = useTheme();
  const { restrictTo } = useContext(AuthContext);
  return (
    <>
      <ActiveLinkFunction href="/">
        {({ isActive }) => (
          <>
            {isActive ? (
              <MenuButtonActive
                onClick={onClickFunc}
                size="large"
                variant="text"
              >
                Home
              </MenuButtonActive>
            ) : (
              <MenuButton onClick={onClickFunc} size="large" variant="text">
                Home
              </MenuButton>
            )}
          </>
        )}
      </ActiveLinkFunction>

      <ActiveLinkFunction href="/units" matchRoot={true}>
        {({ isActive }) => (
          <>
            {isActive ? (
              <MenuButtonActive
                onClick={onClickFunc}
                size="large"
                variant="text"
              >
                Unit
              </MenuButtonActive>
            ) : (
              <MenuButton onClick={onClickFunc} size="large" variant="text">
                Unit
              </MenuButton>
            )}
          </>
        )}
      </ActiveLinkFunction>
      {restrictTo([
        'admin',
        'owner_admin',
        'manufacturer_admin',
        'client_admin',
        'owner_admin',
        'manufacturer_admin',
        'client_admin',
      ]) && (
        <ActiveLinkFunction
          href="/alarms/overview"
          matchAlt="/alarms"
          matchRoot={true}
        >
          {({ isActive }) => (
            <>
              {isActive ? (
                <MenuButtonActive
                  onClick={onClickFunc}
                  size="large"
                  variant="text"
                >
                  Alarms
                </MenuButtonActive>
              ) : (
                <MenuButton onClick={onClickFunc} size="large" variant="text">
                  Alarms
                </MenuButton>
              )}
            </>
          )}
        </ActiveLinkFunction>
      )}

      {restrictTo([
        'admin',
        'owner_admin',
        'manufacturer_admin',
        'client_admin',
      ]) && (
        <ActiveLinkFunction
          href="/admin/users"
          matchAlt="/admin"
          matchRoot={true}
        >
          {({ isActive }) => (
            <>
              {isActive ? (
                <MenuButtonActive
                  onClick={onClickFunc}
                  size="large"
                  variant="text"
                >
                  Admin
                </MenuButtonActive>
              ) : (
                <MenuButton onClick={onClickFunc} size="large" variant="text">
                  Admin
                </MenuButton>
              )}
            </>
          )}
        </ActiveLinkFunction>
      )}
    </>
  );
}
export default HeaderButtonList;
