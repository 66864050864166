import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  borderRadius: 10,

  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

export default MenuButton;
