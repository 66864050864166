import { useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { DashboardContext } from '@/store/dashboard-context';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
export default function AddCard(props) {
  const { item, buttonProps = {} } = props;
  const { insertEmptyCard } = useContext(DashboardContext);

  const handleClick = (after) => () => {
    if (item.position[3].length == 1) {
      insertEmptyCard(item.position, [12, 1], after);
    } else {
      insertEmptyCard(item.position, [3, 1], after);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: 1,
          borderRadius: '1rem',
          borderBlockColor: 'secondary.main',
          p: 0,
          m: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title={'Add card before'} placement="top">
          <IconButton onClick={handleClick(false)} {...buttonProps}>
            <ArrowLeftIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <IconButton disabled {...buttonProps}>
          <NoteAddIcon fontSize="small" />
        </IconButton>
        <Tooltip title={'Add card after'} placement="top">
          <IconButton onClick={handleClick(true)} {...buttonProps}>
            <ArrowLeftIcon
              fontSize="small"
              sx={{ transform: 'rotate(180deg)' }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}
