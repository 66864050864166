export const objEmptyOrUndefined = (obj) => {
  // 👈 null and undefined check
  if (obj) {
    if (
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const copyObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

//multiply
export const multiply = (a, b) => {
  //case where a is an array
  if (Array.isArray(a)) {
    //case of two arrays
    if (Array.isArray(b)) {
      return a.map((val, ind) => {
        return val * b[ind];
      });
      //case of array multiplied by singular value
    } else {
      return a.map((val) => {
        return val * b;
      });
    }
    // case where b is an array and a is not
  } else if (Array.isArray(b)) {
    return b.map((val) => {
      return val * a;
    });
    //case where neither a or b are array
  } else {
    return a * b;
  }
};

export const moveItem = (arr, from, to) => {
  const copy = [...arr];
  const f = copy.splice(from, 1)[0];
  copy.splice(to, 0, f);
  return copy;
};

//interpolation function where x and y are arrays, where x is monotonically increasing, and x1 is the value to be interpolated
export const interpolate = (x, y, x1) => {
  //assure x and y are of same length
  if (x.length != y.length) throw new Error('x and y should be of same length');
  if (x1 <= x[0]) return y[0];
  if (x1 >= x[x.length - 1]) return y[y.length - 1];

  for (let i = 0; i < x.length; i++) {
    if (x[i] > x1) {
      const m = (y[i] - y[i - 1]) / (x[i] - x[i - 1]);
      return y[i - 1] + m * (x1 - x[i - 1]);
    }
  }
};
