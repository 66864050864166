import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function RenderLoading(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        verticalAlign: 'middle',
        alignItems: 'center',
        height: '100%',
        paddingBottom: '0.5rem',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
