import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Assistant, Montserrat } from 'next/font/google';
// import localFont from "next/font/local";

// If loading a variable font, you don't need to specify the font weight
export const assistant = Assistant({ subsets: ['latin'] });
export const monteserrat = Montserrat({ subsets: ['latin'] });

let theme = createTheme({
  typography: {
    fontFamily: [
      monteserrat.style.fontFamily,
      assistant.style.fontFamily,
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  palette: {
    primary: {
      light: '#f0f6f1',
      main: '#16ae76',
      dark: '#00442d',
      lightTransparent: '#f0f6f1cf',
      areaChart: '#b5d4bacf',
      mainTransparent: '#15ac75cf',
      darkTransparent: '#00442dcf',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f7739d',
      main: '#f7739d',
      dark: '#f88bae',
      contrastText: '#fff',
      contrastText2: '#fdd7e3',
    },
    alarm: {
      ok: '#16ae76',
      warning: '#eeb417',
      danger: '#f7739d',
      unavailable: '#a1a1a1cf',
    },
    appBar: {
      light: '#ffffffe6',
      main: '#ffffffe6',
      dark: '#ffffffe6',
      contrastText: '#ffffffe6',
    },
  },
  menuPaper: {
    backgroundColor: '#004268',
  },
});
theme = responsiveFontSizes(theme);
export default theme;
