import React, { useState, useContext } from 'react';
import Image from 'next/image';
import { styled } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HeaderButtonList from './HeaderButtonList';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { AuthContext } from '@/store/auth-context';
const IconButtonActive = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.light,
  fontWeight: 700,
  borderRadius: 10,
  '&:hover': {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
}));

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const onClick = () => setOpenDrawer(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'appBar.main',
            width: '100%',
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ mt: 4 }} disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Image
                src="/images/logo-watermeln.svg"
                width={240}
                height={50}
                alt="Picture of the author"
              />
            </Box>
            <IconButtonActive onClick={() => setOpenDrawer(!openDrawer)}>
              <CloseIcon />
            </IconButtonActive>
          </Toolbar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: '30vh',
            }}
          >
            {isLoggedIn && <HeaderButtonList onClickFunc={onClick} />}
          </Box>
        </Container>
      </Drawer>
      {isLoggedIn && (
        <IconButtonActive onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon fontSize="small" />
        </IconButtonActive>
      )}
    </>
  );
}
export default DrawerComponent;
