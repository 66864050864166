import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import postData from '@/utils/postData';
import { useEmail, useFirstName, usePassword } from './loginFormValidations';
import { AuthContext } from '@/store/auth-context';
function UpdatePassword(props) {
  //property that sets whether to show register or login

  const { setFormToShow, openLoginModal } = useContext(AuthContext);

  const [originalPassword, setOriginalPassword] = useState('');
  const [alertType, setAlertType] = useState('error');
  //password states and validation logic
  const {
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isPasswordValid,
    passwordErrorMessage,
  } = usePassword();

  //state to hide and show password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //state to show error message
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');

  //to apply color
  const theme = useTheme();

  //function to remove error message
  useEffect(() => {
    if (isPasswordValid) {
      setOpenAlert(false);
    }
  }, [isPasswordValid]);

  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
      setMessage('');
      setOriginalPassword('');
      setPassword('');
      setPasswordConfirm('');
    }
  }, [openLoginModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password: originalPassword.trim(),
      newPassword: password.trim(),
      passwordConfirm: passwordConfirm.trim(),
    };

    //check on validations
    if (
      !isPasswordValid ||
      password.length === 0 ||
      passwordConfirm.length === 0 ||
      originalPassword.length === 0
    ) {
      setMessage('Please correct all errors');
      setAlertType('error');
      setOpenAlert(true);
    } else {
      const [returnData, success, postMessage] = await postData(
        data,
        true,
        '/users/updateMyPassword'
      );
      if (!success) {
        setMessage(postMessage);
        setAlertType('error');
        setOpenAlert(true);
      } else {
        setMessage('Password updated');
        setAlertType('success');
        setOpenAlert(true);
        setOriginalPassword('');
        setPassword('');
        setPasswordConfirm('');
      }
    }
  };

  return (
    <>
      <FormGroup>
        <Typography
          variant="h6"
          color="secondary.contrastText"
          sx={{ mb: 1, fontWeight: 'bold' }}
        >
          Update password
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            '&.Mui-disabled': {
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
            width: '100%',
          }}
          onClick={() => setFormToShow('updateProfile')}
        >
          {'<Back'}
        </Button>
        <TextField
          id="password-original"
          label="Password original"
          name="password original"
          variant="outlined"
          sx={{ mt: 1 }}
          type={showPassword ? 'text' : 'password'}
          value={originalPassword != null ? originalPassword : ''}
          onChange={(e) => setOriginalPassword(e.target.value)}
          error={openAlert && originalPassword.length === 0}
          helperText={
            openAlert && originalPassword.length === 0
              ? 'please provide a password'
              : ''
          }
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        <TextField
          id="passwordNew"
          label="Password new"
          name="password"
          variant="outlined"
          sx={{ mt: 1 }}
          type={showPassword ? 'text' : 'password'}
          value={password != null ? password : ''}
          onChange={(e) => setPassword(e.target.value)}
          error={
            (password.length > 0 && !isPasswordValid) ||
            (openAlert && password.length === 0)
          }
          helperText={
            (password.length > 0 && !isPasswordValid
              ? passwordErrorMessage
              : '') +
            (openAlert && password.length === 0
              ? 'please provide a password'
              : '')
          }
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        <TextField
          id="passwordConfirm"
          label="Password confirm"
          variant="outlined"
          sx={{ mt: 1 }}
          type={showPassword ? 'text' : 'password'}
          value={passwordConfirm != null ? passwordConfirm : ''}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          error={passwordConfirm.length > 0 && !isPasswordValid}
          helperText={
            passwordConfirm.length > 0 && !isPasswordValid
              ? passwordErrorMessage
              : ''
          }
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        <Button
          color="primary"
          variant="contained"
          sx={{ mt: 1, width: '100%' }}
          onSubmit={handleSubmit}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {openAlert && <Alert severity={alertType}>{message}</Alert>}
      </FormGroup>
    </>
  );
}
export default UpdatePassword;
