import { useContext } from 'react';
import useIsMobile from '@/hooks/useIsMobile';
import { DashboardContext } from '@/store/dashboard-context';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RenderLoading from './RenderLoading';
export default function InfoBox(props) {
  const { title, value, subValue1, subValue2, width, maxWidth } = props;
  const { dataLoading } = useContext(DashboardContext);

  const isMobile = useIsMobile();
  let widthBox = width;
  if (maxWidth) {
    widthBox = Math.min(width, maxWidth);
  }

  const renderResult = () => {
    return (
      <>
        <Typography
          variant={isMobile ? 'body2' : 'subtitle1'}
          color="primary.dark"
          sx={{ fontWeight: 'bold' }}
        >
          {title}
        </Typography>
        <Typography
          variant={isMobile ? 'body2' : 'h6'}
          color="primary.dark"
          sx={{ fontWeight: 'bold' }}
        >
          {value}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            color="primary.dark"
          >
            {subValue1}
          </Typography>
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            color="primary.dark"
          >
            {subValue2}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: widthBox,
      }}
    >
      {dataLoading.value ? <RenderLoading /> : renderResult()}
    </Box>
  );
}
