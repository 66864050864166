// convert number to string
export const numberToString = (decimals) => (value) => {
  return (value * 1.0).toFixed(decimals);
};

//scale value before turning into string
export const numberScalerToString = (scaling, decimals) => (value) => {
  return (value * scaling).toFixed(decimals);
};

export const numberScalerPercentage = (decimals) => (value) => {
  return `${(value * 100).toFixed(decimals)}%`;
};
export const toString = (value) => String(value);
export const dateToString = (value) => value.split('T')[0];
export const grToKg = (value, reverse) => {
  return reverse ? value * 1000 : value / 1000;
};
export const mgpsToKgph = (value, reverse) => {
  return reverse ? (value * 1000) / 3600 : (value / 1000) * 3600;
};

export const conversion = (value, func, decimals = null, reverse = false) => {
  const allConversions = {
    grToKg,
    mgpsToKgph,
  };
  if (func in allConversions) {
    if (decimals == null) {
      return allConversions[func](value, reverse).toString();
    } else {
      return allConversions[func](value, reverse).toFixed(decimals);
    }
  } else {
    return decimals == null
      ? Number(value).toString()
      : Number(value).toFixed(decimals);
  }
};
