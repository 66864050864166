import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import theme from '@/components/theme';

import { popoverClasses } from '@mui/material/Popover';

/**
 * Creates a parameter selection menu component.
 *
 * @param {Object} props - The props for the component.
 * @param {ReactNode} props.children - The children of the component.
 * @param {function} props.onChange - The function to call when the value of autocomplete changes.
 * @param {any} props.value - The value of the component. (list for multiple, single value else)
 * @param {Array|Object|null} props.options - The options for the menu. This can be either an array of objects, or an object where the key is the value
 * @param {string} props.labelIdentifier - The identifier for the label of the menu.
 * @param {function} [props.additionalArgsOnChange=[]] - Additional arguments to give to onChange function like onChange(newValue, ...additionalArgsOnChange)
 * @param {Object} [props.buttonProps={}] - Additional props for the button.
 * @param {Object} [props.autocompleteProps={}] - Additional props for the autocomplete (like sx and multiple).
 * @param {boolean} [props.onHover=true] - Whether to show the menu on hover.
 * @param {boolean} [props.menuId=""] - Whether to show the menu on hover.
 * @param {ReactNode} [props.selectionHeader] -Header to show above autocomplete e.g. (<Typography>label</Typography>)
 * @return {ReactNode} The rendered component.
 */
function SelectionMenuWrapper({
  children,
  onChange,
  value,
  options,
  labelIdentifier,
  selectionHeader,
  additionalArgsOnChange = [],
  buttonProps = {},
  autocompleteProps = {},
  onHover = true,
  menuId = 'menu',
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let optionsInput;
  let initialVal = '';
  if (options == null) {
    optionsInput == 0;
  } else if (Array.isArray(options)) {
    optionsInput = options.length > 0 ? [...options, ''] : [];

    autocompleteProps.getOptionKey = (option) => {
      option[labelIdentifier];
    };
  } else {
    optionsInput =
      Object.keys(options).length > 0 ? [...Object.keys(options)] : [];
    //sort keys in order
    optionsInput.sort((a, b) => {
      if ('name' in options[a] && 'name' in options[b]) {
        return options[a].name.localeCompare(options[b].name);
      } else {
        return a.localeCompare(b);
      }
    });
    optionsInput.push('');
    initialVal =
      value != null && value in options ? options[value][labelIdentifier] : '';
  }
  const [inputValue, setInputValue] = useState(initialVal);

  let timeoutId = null;
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (!!timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setAnchorEl(null);
    }, 500);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuEnter = () => {
    if (!!timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const hoverOptionsButton = {
    onMouseLeave: handleClose,
  };
  const hoverOptionsMenu = {
    onMouseOver: handleMenuEnter,
    onMouseLeave: handleMenuClose,
  };
  if (onHover) {
    hoverOptionsButton.onMouseOver = handleClick;
  }

  return (
    <>
      <IconButton
        id={`button-${menuId}`}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...buttonProps}
        {...hoverOptionsButton}
      >
        {children}
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        disableAutoFocus
        MenuListProps={{
          ...hoverOptionsMenu,
          autoFocusItem: false,
        }}
        sx={{
          [`&.${popoverClasses.root}`]: { pointerEvents: 'none' },
          '& .MuiPaper-root': {
            // backgroundColor: theme.palette.primary.main,
            border: '1px solid',
            borderColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.contrastText,
            pointerEvents: 'auto',
          },
        }}
        aria-labelledby="demo-positioned-button"
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem key={`${menuId}-menuitem`}>
          {selectionHeader}
          <Autocomplete
            id={`${menuId}-autocomplete`}
            options={optionsInput}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              event && setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => {
              if (option == null || option === '') return '';
              if (Array.isArray(options)) {
                return typeof option === 'string' || option instanceof String
                  ? option
                  : option[labelIdentifier];
              } else {
                return options[option] == null
                  ? ''
                  : options[option][labelIdentifier];
              }
            }}
            value={value != null ? value : ''}
            onChange={(event, newValue) => {
              onChange(newValue, ...additionalArgsOnChange);
              handleMenuClose();
            }}
            onClose={() => handleMenuClose()}
            renderInput={(params) => (
              <TextField {...params} variant="standard" color="secondary" />
            )}
            {...autocompleteProps}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
export default SelectionMenuWrapper;
