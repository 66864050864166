import { useContext, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BusinessIcon from '@mui/icons-material/Business';
import UpdateIcon from '@mui/icons-material/Update';
import { DashboardContext } from '@/store/dashboard-context';
import { AlarmContext } from '@/store/alarm-context';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { timestampToLocalDateTimeShort } from '@/utils/dateUtil';

import CardBox from './CardBox';
import useIsMobile from '@/hooks/useIsMobile';
import CardCustomization from './customization/CardCustomization';
import TransparentLayer from './customization/TransparentLayer';
import { AuthContext } from '@/store/auth-context';
import fetchData from '@/utils/fetchData';
import Light from './utils/Light';
import { dataAvailable } from './utils/dataAvailable';
import RenderLoading from './utils/RenderLoading';
export default function NameCard(props) {
  const {
    editMode,
    unitIsOnline,
    valueData,
    statusCodes,
    unitData,
    dataLoading,
  } = useContext(DashboardContext);
  const { restrictTo } = useContext(AuthContext);
  const { alarmData } = useContext(AlarmContext);
  const [highlight, setHighlight] = useState({});
  const [lineHeight, setLineHeight] = useState(20);
  const titleRef = useRef();
  const router = useRouter();
  const { item } = props;
  //   const { content, dataId } = item;
  const isMobile = useIsMobile('sm');

  //handle highlight
  useEffect(() => {
    //check
    if (
      !objEmptyOrUndefined(alarmData) &&
      alarmData.category === 'cardNonEmptyError'
    ) {
      //check if we are mentioned in the alarm
      const foundSelf = alarmData.data.nonEmpty.filter(
        (card) => card.position[3] == item.position[3]
      );
      if (foundSelf.length > 0) {
        setHighlight({
          '@keyframes pulse': {
            '0%, 100%': {
              backgroundColor: 'primary.light',
            },
            '50%': {
              backgroundColor: 'alarm.danger',
            },
          },
          animation: 'pulse 1s infinite',
        });
        setTimeout(() => {
          setHighlight({});
        }, alarmData.duration);
      }
    }
  }, [alarmData]);

  const renderResult = () => {
    return (
      <>
        <Typography
          variant={isMobile ? 'body2' : 'subtitle1'}
          sx={{ fontWeight: 'bold' }}
          color="primary.dark"
        >
          {dataAvailable(unitData, 'name') && unitData.name}
        </Typography>

        <Grid container>
          <Grid
            item
            xs={3}
            sm={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              // src="/images/favicon/apple-touch-icon.png"
              src="/images/unit.png"
              width={isMobile ? 50 : 67}
              height={isMobile ? 50 : 67}
              alt="logo"
            />
          </Grid>
          <Grid item xs={9} sm={8}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '0.5rem',
              }}
            >
              <PowerSettingsNewIcon fontSize="small" color="primary" />
              <Typography
                variant="body2"
                color={unitIsOnline ? 'primary.main' : 'secondary.main'}
                sx={{ marginLeft: '0.5rem' }}
              >
                {unitIsOnline ? 'Online' : 'Offline'}
              </Typography>
              {unitIsOnline &&
                dataAvailable(valueData, 'FuelCell--Stoplicht') &&
                !objEmptyOrUndefined(statusCodes) && (
                  <Light
                    lightOn={
                      valueData['FuelCell--Stoplicht'] in
                      statusCodes.trafficLightCodes
                        ? statusCodes.trafficLightCodes[
                            valueData['FuelCell--Stoplicht']
                          ].lightOn
                        : false
                    }
                    size={12}
                    color={
                      valueData['FuelCell--Stoplicht'] in
                      statusCodes.trafficLightCodes
                        ? statusCodes.trafficLightCodes[
                            valueData['FuelCell--Stoplicht']
                          ].color
                        : 'grey'
                    }
                    divProps={{ marginLeft: '0.5rem' }}
                    blink={
                      valueData['FuelCell--Stoplicht'] in
                      statusCodes.trafficLightCodes
                        ? statusCodes.trafficLightCodes[
                            valueData['FuelCell--Stoplicht']
                          ].flash
                        : false
                    }
                    description={
                      valueData['FuelCell--Stoplicht'] in
                      statusCodes.trafficLightCodes
                        ? statusCodes.trafficLightCodes[
                            valueData['FuelCell--Stoplicht']
                          ].description
                        : 'Traffic Light code not defined, please contact administrator'
                    }
                  />
                )}
            </Box>
            {restrictTo(['admin', 'owner_admin', 'owner_viewer']) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                }}
              >
                <BusinessIcon fontSize="small" color="primary" />
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{ marginLeft: '0.5rem' }}
                >
                  {dataAvailable(unitData, 'client') &&
                    `${unitData?.client?.name}`}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '0.5rem',
              }}
            >
              <UpdateIcon fontSize="small" color="primary" />

              <Typography variant="caption" sx={{ marginLeft: '0.5rem' }}>
                {dataAvailable(valueData, 'time') &&
                  `${timestampToLocalDateTimeShort(valueData.time)}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  //calculate line hight
  useEffect(() => {
    if (titleRef.current) {
      const lineHeightNew = window
        .getComputedStyle(titleRef.current, null)
        .getPropertyValue('line-height');
      setLineHeight(parseFloat(lineHeightNew.replace('px', '')));
    }
  }, [titleRef]);

  return (
    <CardBox height={1}>
      <Card
        sx={{
          paddingTop: editMode ? '1.2rem' : '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.light',
          position: 'relative',
          ...highlight,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-end',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent sx={{ padding: '0.5rem' }}>
          {dataLoading.value || dataLoading.unit ? (
            <RenderLoading />
          ) : (
            renderResult()
          )}

          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const nameCardObj = (position, size) => {
  const obj = {
    type: 'NameCard',
    typeLabel: `Name Card ${size[0] / 3}x${size[1]}`,
    content: {},
    dataId: '',
    size,
    position,
  };
  return obj;
};
