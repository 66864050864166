import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Light from './Light';
import { dataAvailable } from './dataAvailable';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '@/store/dashboard-context';
import Switch from '@mui/material/Switch';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import { Grid, Typography, useTheme } from '@mui/material';
import { objEmptyOrUndefined } from '@/utils/arrayFunctions';
import { AuthContext } from '@/store/auth-context';
import { useRouter } from 'next/router';
import fetchData from '@/utils/fetchData';
import UpdateIcon from '@mui/icons-material/Update';
import { timestampToLocalDateTimeShort } from '@/utils/dateUtil';
import useIsMobile from '@/hooks/useIsMobile';
import SimpleTable from '@/components/Table/SimpleTable';
export default function StatusBox(props) {
  const { unitIsOnline, valueData, statusCodes, unitData } =
    useContext(DashboardContext);

  const { isLoggedIn, restrictTo } = useContext(AuthContext);

  const theme = useTheme();
  const router = useRouter();

  const isMobile = useIsMobile();

  const tableData = [];

  const getTableData = () => {
    //customer
    let customerData = [];
    if (
      !objEmptyOrUndefined(unitData) &&
      restrictTo([
        'admin',
        'owner_admin',
        'owner_viewer',
        'client_admin',
        'client_viewer',
      ])
    ) {
      customerData = [
        'Customer:',
        dataAvailable(unitData, 'client') ? unitData?.client?.name : '',
      ];
    }
    const gridData = ['Grid:', 'Making grid'];
    //FC status

    let fcStatus = '';
    if (
      unitIsOnline &&
      dataAvailable(valueData, 'FuelCell--Stoplicht') &&
      !objEmptyOrUndefined(statusCodes)
    ) {
      fcStatus =
        valueData['FuelCell--Stoplicht'] in statusCodes.trafficLightCodes
          ? statusCodes.trafficLightCodes[valueData['FuelCell--Stoplicht']]
              .description
          : '';
    }
    const fcData = ['FC Status:', fcStatus];

    return {
      customerData,
      gridData,
      fcData,
    };
  };

  const { customerData, gridData, fcData } = getTableData();
  if (isMobile) {
    if (customerData.length > 0) {
      tableData.push(customerData);
    }
    //grid
    tableData.push(gridData);

    //FC
    tableData.push(fcData);
  } else {
    if (customerData.length > 0) {
      tableData.push([...customerData, ...fcData]);
      tableData.push(gridData);
    } else {
      tableData.push([...gridData, ...fcData]);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Stack alignItems="center" direction="row" gap={2}>
            {unitIsOnline &&
              dataAvailable(valueData, 'FuelCell--Stoplicht') &&
              !objEmptyOrUndefined(statusCodes) && (
                <Light
                  lightOn={
                    valueData['FuelCell--Stoplicht'] in
                    statusCodes.trafficLightCodes
                      ? statusCodes.trafficLightCodes[
                          valueData['FuelCell--Stoplicht']
                        ].lightOn
                      : false
                  }
                  size={18}
                  color={
                    valueData['FuelCell--Stoplicht'] in
                    statusCodes.trafficLightCodes
                      ? statusCodes.trafficLightCodes[
                          valueData['FuelCell--Stoplicht']
                        ].color
                      : 'grey'
                  }
                  divProps={{ marginLeft: '0.5rem' }}
                  blink={
                    valueData['FuelCell--Stoplicht'] in
                    statusCodes.trafficLightCodes
                      ? statusCodes.trafficLightCodes[
                          valueData['FuelCell--Stoplicht']
                        ].flash
                      : false
                  }
                  description={
                    valueData['FuelCell--Stoplicht'] in
                    statusCodes.trafficLightCodes
                      ? statusCodes.trafficLightCodes[
                          valueData['FuelCell--Stoplicht']
                        ].description
                      : 'Traffic Light code not defined, please contact administrator'
                  }
                />
              )}
            <Switch
              checked={unitIsOnline}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {unitIsOnline ? (
              <WifiIcon color="primary" />
            ) : (
              <WifiOffIcon color="secondary" />
            )}
          </Stack>
        </Box>
        <hr style={{ width: '100%', color: theme.palette.primary.dark }} />
        <SimpleTable data={tableData} />
        {/* <Grid container>
          <Grid
            item
            xs={12}
            md={'auto'}
            sx={{ display: 'flex', alignItems: 'left' }}
          >
            <Stack
              alignItems="left"
              direction="column"
              sx={{ marginRight: '1rem' }}
            >
              {!objEmptyOrUndefined(unitData) &&
                restrictTo([
                  'admin',
                  'owner_admin',
                  'owner_viewer',
                  'client_admin',
                  'client_viewer',
                ]) && (
                  <Typography variant="caption" color="primary.dark">
                    {'Customer: '}
                  </Typography>
                )}
              <Typography variant="caption" color="primary.dark">
                Mode:
              </Typography>
            </Stack>

            <Stack alignItems="left" direction="column">
              {!objEmptyOrUndefined(unitData) &&
                restrictTo([
                  'admin',
                  'owner_admin',
                  'owner_viewer',
                  'client_admin',
                  'client_viewer',
                ]) && (
                  <Typography variant="caption" color="primary.dark">
                    {dataAvailable(unitData, 'client') &&
                      `${unitData?.client?.name}`}
                  </Typography>
                )}
              <Typography variant="caption" color="primary.dark">
                Making grid
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={'auto'}
            sx={{
              paddingLeft: { md: '1rem' },
              display: 'flex',
              alignItems: 'left',
            }}
          >
            <Stack
              alignItems="left"
              direction="column"
              sx={{ marginRight: '1rem' }}
            >
              <Typography variant="caption" color="primary.dark">
                FC status:
              </Typography>
              <Typography variant="caption" color="primary.dark">
                AC status:
              </Typography>
            </Stack>
            <Stack alignItems="left" direction="column">
              <Typography variant="caption" color="primary.dark">
                {unitIsOnline &&
                dataAvailable(valueData, 'FuelCell--Stoplicht') &&
                !objEmptyOrUndefined(statusCodes) &&
                valueData['FuelCell--Stoplicht'] in
                  statusCodes.trafficLightCodes
                  ? statusCodes.trafficLightCodes[
                      valueData['FuelCell--Stoplicht']
                    ].description
                  : ''}
              </Typography>
              <Typography variant="caption" color="primary.dark"></Typography>
            </Stack>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
}
