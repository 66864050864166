import { objEmptyOrUndefined } from './arrayFunctions';

//send data to api
const postData = async (inputs, update, endpoint, onUnauthorized = null) => {
  /**
   * Posts data from to the specified endpoint.
   *
   * @param {any} inputs - Data to send
   * @param {boolean} update - Whether the data is an update or not
   * @param {string} endpoint - The API endpoint send data to
   * @param {function} onUnauthorized - Function to call if unauthorized
   * @return {[any, boolean, string]} - [data, success, message] An array containing the response data, a boolean indicating whether the request was successful, and an error message if any.
   */
  //set up headers
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  //prepare body
  const raw = JSON.stringify(inputs);
  // set request options
  const requestOptions = {
    headers: myHeaders,
    credentials: 'include',
  };

  if (inputs != null && !objEmptyOrUndefined(inputs)) {
    requestOptions.body = raw;
  }
  //get api url from environment
  let url = process.env.NEXT_PUBLIC_API_URL;

  // set request method and url based on conditions
  if (update) {
    requestOptions.method = 'PATCH';
    url = `${url}${endpoint}`;
  } else {
    requestOptions.method = 'POST';
    url = `${url}${endpoint}`;
  }

  // try to get results
  let response;
  try {
    // send request
    let rawResponse = await fetch(url, requestOptions);
    // process output
    response = await rawResponse.json();
    // check for unauthorized
    if (rawResponse.status === 401) {
      let responseMessage = 'authorization error';
      if (response && response.message) {
        responseMessage = response.message;
      }
      if (onUnauthorized !== null) {
        onUnauthorized(responseMessage);
      }
      return [null, false, response.message];
    }
  } catch (error) {
    response = error;
  } finally {
    if (response && response.data && response.data.data) {
      return [response.data.data, true, ''];
    } else if (response && response.status) {
      if (response.status === 'succes' || response.status === 'success') {
        if (response.data) {
          return [response.data, true, response.message];
        } else {
          return [null, true, response.message];
        }
      } else {
        return [null, false, response.message];
      }
    }
    return [null, false, 'Something happened'];
  }
};

export default postData;
