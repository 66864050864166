import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';

import theme, { assistant, monteserrat } from '@/components/theme';
import Footer from '@/components/Footer';
import Header from '@/components/Header/Header';
import AuthContextProvider from '@/store/auth-context';
import DashboardContextProvider from '@/store/dashboard-context';
import AlarmContextProvider from '@/store/alarm-context';
import SettingsContextProvider from '@/store/settings-context';
import AlarmGeneral from '@/components/utils/AlarmGeneral';
import EnforceTermsConditions from '@/components/utils/EnforceTermsConditions';
export default function App({ Component, pageProps }) {
  return (
    <ThemeProvider theme={theme}>
      <AlarmContextProvider>
        <AuthContextProvider>
          <DashboardContextProvider>
            <SettingsContextProvider>
              <Head>
                <title>Watermeln</title>

                <meta name="description" content="aggregaat, waterstof" />
                <meta content="website" property="og:type"></meta>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <link rel="icon" href="/favicon.png" />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/images/favicon/favicon-16x16.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/images/favicon/favicon-32x32.png"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/images/favicon/apple-touch-icon.png"
                />
              </Head>
              <React.StrictMode>
                <CssBaseline />
                <main
                  className={`${assistant.className} ${monteserrat.className}`}
                >
                  <Header />
                  <Component {...pageProps} />
                  <EnforceTermsConditions />
                  <AlarmGeneral />
                  <Footer />
                </main>
              </React.StrictMode>
            </SettingsContextProvider>
          </DashboardContextProvider>
        </AuthContextProvider>
      </AlarmContextProvider>
    </ThemeProvider>
  );
}
