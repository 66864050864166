import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './auth-context';
import fetchData from '@/utils/fetchData';

export const SettingsContext = createContext({
  setZoom: () => {},
  zoom: [],
  setReloadSettings: () => {},
  setUserSettings: () => {},
  userSettings: {},
});

const SettingsContextProvider = ({ children }) => {
  const [zoom, setZoom] = useState([0, 100]);
  const [userSettings, setUserSettings] = useState({});
  const [reloadSettings, setReloadSettings] = useState(0);

  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    const getSettings = async () => {
      const [data, success, errorMessage] = await fetchData(
        '/settings/my',
        'doc'
      );
      if (success) {
        setUserSettings(data);
      }
    };

    if (isLoggedIn) {
      getSettings();
    }
  }, [isLoggedIn, reloadSettings]);

  return (
    <SettingsContext.Provider
      value={{
        setZoom,
        zoom,
        setReloadSettings,
        setUserSettings,
        userSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
