import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import { styled, useTheme } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardCustomization from './customization/CardCustomization';
import CardBox from './CardBox';
import Button from '@mui/material/Button';
import useIsMobile from '@/hooks/useIsMobile';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TransparentLayer from './customization/TransparentLayer';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import fetchData from '@/utils/fetchData';
import { timestampToLocalDateTimeShort } from '@/utils/dateUtil';
import postData from '@/utils/postData';
import { DashboardContext } from '@/store/dashboard-context';
import { AlarmContext } from '@/store/alarm-context';
import GeneralDialogMenu from '@/components/utils/GeneralDialogMenu';

export default function CommandCard(props) {
  const { editMode, refreshData } = useContext(DashboardContext);
  const { setAlarmData } = useContext(AlarmContext);
  const [lastCommand, setLastCommand] = useState([]);
  const [lastCommandTime, setLastCommandTime] = useState(0);
  const [commandIssued, setCommandIssued] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    msg: '',
    title: '',
    acceptAction: () => {},
    cancelAction: () => {},
    acceptLabel: 'Accept',
    cancelLabel: 'Cancel',
  });
  const isMobile = useIsMobile('sm');
  const { item } = props;
  const router = useRouter();
  const { id: unitId } = router.query;
  let intervalId;
  const getUnitLastCommand = async () => {
    const [data, success, errorMessage] = await fetchData(
      `/units/${unitId}?fields=lastCommand`,
      'unit'
    );

    if (success) {
      if (data.lastCommand == null) {
        if (commandIssued) {
          setLastCommand(['Sending Command']);
        } else {
          setLastCommand([]);
        }
        return;
      }
      if (
        data.lastCommand.feedback == null ||
        (data.lastCommand.feedback && data.lastCommand.feedback.length == 0)
      ) {
        setLastCommand(['Sending Command', 'Command issued']);
        setLastCommandTime(data.lastCommand.createdAt);
        return;
      }
      if (data.lastCommand.feedback && data.lastCommand.feedback.length > 0) {
        const feedbackObj = {};
        data.lastCommand.feedback.forEach((feedback) => {
          if (feedback.message == 'received') {
            feedbackObj.received = feedback.time;
          }
          if (feedback.message == 'completed') {
            feedbackObj.completed = feedback.time;
          }
        });
        const commands = ['Sending Command', 'Command issued'];
        let lastTime = data.lastCommand.createdAt;
        if ('received' in feedbackObj) {
          commands.push('Received by Unit');
          lastTime = feedbackObj.received;
        }
        if ('completed' in feedbackObj) {
          commands.push('Completed');
          lastTime = feedbackObj.completed;
          clearInterval(intervalId);
        }
        setLastCommand(commands);
        setLastCommandTime(lastTime);
      }
    }
  };
  useEffect(() => {
    getUnitLastCommand();
  }, [refreshData]);
  useEffect(() => {
    if (lastCommand.length === 4) {
      clearInterval(intervalId);
    }
  }, [lastCommand]);

  const handleClick = () => {
    setDialog({
      open: true,
      msg: `Are you sure you want to turn off the unit? It cannot be turned back on from the dashboard!`,
      title: 'Turn Off Unit',
      acceptAction: () => {
        turnUnitOff();
        setDialog({ ...dialog, open: false });
      },
      cancelAction: () => {
        setDialog({ ...dialog, open: false });
      },
      acceptLabel: 'Turn Off',
      cancelLabel: 'Cancel',
    });
  };
  const turnUnitOff = async () => {
    const [data, success, errorMessage] = await postData(
      {},
      false,
      `/units/${unitId}/cmd/turnOff`
    );
    if (success) {
      setCommandIssued(true);
      setLastCommandTime(Date.now());
      intervalId = setInterval(() => {
        getUnitLastCommand();
      }, 1500);
    } else {
      setAlarmData({
        type: 'error',
        msg: 'Failed to send command to backend',
        duration: 6000,
        category: 'unitCommandSendError',
      });
    }
  };

  return (
    <CardBox height={1}>
      <Card
        sx={{
          paddingTop: editMode ? '1.2rem' : '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.light',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent sx={{ padding: '0.5rem', height: '100%' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={6} sx={{ height: '100%' }}>
              <Typography
                variant={isMobile ? 'caption' : 'body2'}
                color="primary.dark"
              >
                Turn Off
              </Typography>

              <IconButton
                color="secondary"
                sx={{ mt: '.5rem' }}
                onClick={handleClick}
              >
                <PowerSettingsNewIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={6} sx={{ height: '100%' }}>
              {lastCommand.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Typography variant={isMobile ? 'caption' : 'body2'}>
                    {lastCommand.length > 0
                      ? lastCommand[lastCommand.length - 1]
                      : 'Awaiting input'}
                  </Typography>
                  <CircularProgress
                    variant="determinate"
                    value={
                      lastCommand.length > 0
                        ? (100 / 4) * lastCommand.length
                        : 0
                    }
                  />
                  <Typography variant="caption">
                    {timestampToLocalDateTimeShort(lastCommandTime)}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <GeneralDialogMenu dialog={dialog} setDialog={setDialog} />
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const commandCardObj = (position, size) => {
  const obj = {
    type: 'CommandCard',
    typeLabel: `Command Card ${size[0] / 3}x${size[1]}`,
    commandType: 'turnOff',
    position,
    size: size || [6, 1],
  };
  return obj;
};
