exports.getTextfieldSx = (color) => {
  return {
    '& label': {
      color: `${color}`,
    },
    '& label.Mui-focused': {
      color: `${color}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${color}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${color}`,
      },
      '&:hover fieldset': {
        borderColor: `${color}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${color}`,
      },
    },
  };
};
