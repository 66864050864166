import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material';

import { useEmail } from './loginFormValidations';
import postData from '@/utils/postData';
import { AuthContext } from '@/store/auth-context';
function ForgotPasswordRequest(props) {
  //email state and validation logic
  const { email, setEmail, isEmailValid } = useEmail();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState('error');
  const [disableButton, setDisableButton] = useState(false);

  //context
  const { setFormToShow, openLoginModal } = useContext(AuthContext);
  //to apply color
  const theme = useTheme();

  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
      setEmail('');
    }
  }, [openLoginModal]);

  //submit form to backend for login
  //check for email and password
  //message on error and close on succes
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email.trim(),
    };

    //check on validations
    if (!isEmailValid || email.length === 0) {
      setAlertType('error');
      setOpenAlert(true);
      setMessage('Please correct all errors');
    } else {
      await postData(data, false, '/users/forgotPassword');
      setAlertType('success');
      setOpenAlert(true);
      setDisableButton(true);
      setMessage(
        'In case provided email adress is registered, a password reset link is sent. The link is valid for 10 minutes.'
      );
    }
  };

  return (
    <>
      <FormGroup>
        <Typography
          variant="h6"
          color="secondary.contrastText"
          sx={{ mb: 1, fontWeight: 'bold' }}
        >
          Request password reset link
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            '&.Mui-disabled': {
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
            width: '100%',
          }}
          onClick={() => setFormToShow('login')}
        >
          {'<Back'}
        </Button>

        <TextField
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          variant="outlined"
          sx={{ mt: 1 }}
          onChange={(e) => setEmail(e.target.value)}
          error={
            (email.length > 0 && !isEmailValid) ||
            (openAlert && email.length === 0)
          }
          helperText={
            (email.length > 0 && !isEmailValid
              ? 'please provide a valid email'
              : '') +
            (openAlert && email.length === 0 ? 'please provide an email' : '')
          }
        />

        <Button
          color="primary"
          variant="contained"
          sx={{ mt: 1, width: '100%' }}
          onSubmit={handleSubmit}
          onClick={handleSubmit}
          disabled={disableButton}
        >
          Submit
        </Button>
        {openAlert && <Alert severity={alertType}>{message}</Alert>}
      </FormGroup>
    </>
  );
}
export default ForgotPasswordRequest;
