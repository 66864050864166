import { useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { DashboardContext } from '@/store/dashboard-context';

export default function RemoveCard(props) {
  const { item, buttonProps = {} } = props;
  const { removeCard } = useContext(DashboardContext);

  const handleClick = () => {
    removeCard(item.position);
  };

  return (
    <Tooltip title={'Remove card'} placement="top">
      <IconButton onClick={handleClick} {...buttonProps}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
