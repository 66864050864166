const replacements = {
  'Fuel cell - Highest pressure': 'FC- Highest pressure',
  'Fuel cell - Mean pressure': 'FC- Mean pressure',
  'Fuel cell - Lowest pressure': 'FC - Lowest pressure',
  'Fuel cell - Current': 'FC - Current',
  'Fuel cell - Ventilator': 'FC - Ventilator',
  'Fuel cell - Highest temp in': 'FC - Highest °C in',
  'Fuel cell - Lowest temp out': 'FC - Lowest °C out',
  'Fuel cell - Highest temp out': 'FC - Highest °C out',
  'Battery - Max temp cells': 'Battery - Max °C cells',
  'Battery - Mean temp cells': 'Battery - Mean °C cells',
  'Battery - Min temp cells': 'Battery - Min °C cells',
  'Battery - Max voltage cells': 'Battery - Max V cells',
  'Battery - Min voltage cells': 'Battery - Min V cells',
  'AC inverter - Voltage phase 1': 'AC inverter - V phase 1',
  'AC inverter - Voltage phase 2': 'AC inverter - V phase 2',
  'AC inverter - Voltage phase 3': 'AC inverter - V phase 3',
  'AC inverter - Frequency': 'AC inverter - Freq',
};

export const replaceName = (name) => {
  if (name in replacements) {
    return replacements[name];
  } else {
    return name;
  }
};
