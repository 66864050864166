import { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DashboardContext } from '@/store/dashboard-context';
export default function TransparentLayer(props) {
  const { item } = props;
  const { editMode, getItem } = useContext(DashboardContext);
  const theme = useTheme();

  let columnNumber = 1;

  //this is a dirty trick to force a rerender, if not we get the wrong column number
  if (useMediaQuery(theme.breakpoints.up('md'))) columnNumber = 2;
  if (useMediaQuery(theme.breakpoints.up('lg'))) columnNumber = 3;

  let positionToShow =
    columnNumber in item.position
      ? `${item.position[columnNumber]}`
      : `${item.position[3][0]}`;

  //nested card handling
  if (columnNumber < 3 && item.position[3].length > 1) {
    //we need to check the parent positioning for index1
    const parentItem = getItem([item.position[3][0]]);
    if (parentItem != null) {
      const pos0 =
        columnNumber in parentItem.position
          ? parentItem.position[columnNumber][0]
          : parentItem.position[3][0];
      positionToShow = `${pos0},${item.position[3][1]}`;
    }
  }

  let additionalGraphics = {};
  if (editMode)
    additionalGraphics = {
      zIndex: 5,
      background: 'rgba(255, 255, 255, 0.55)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

  return (
    <>
      {editMode && (
        <Box sx={additionalGraphics}>
          {/* {columnNumber === 1 && columnNumber in item.position && (
            <Chip
              color="secondary"
              variant="filled"
              label={`1 col view position: \n${positionToShow}`}
            />
          )}
          {columnNumber === 2 && columnNumber in item.position && (
            <Chip
              color="secondary"
              variant="filled"
              label={`1 col view position: ${positionToShow}`}
            />
          )}
          {(columnNumber === 3 || !(columnNumber in item.position)) && (
            <Chip
              color="secondary"
              variant="filled"
              label={`${columnNumber} col view, pos:${positionToShow}`}
            />
          )} */}
        </Box>
      )}
    </>
  );
}
