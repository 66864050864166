import moment from 'moment';
import Typography from '@mui/material/Typography';
import { conversion } from '@/utils/formatFunctions';

const headerFormat = (value) => {
  return (
    <Typography
      variant="body"
      component="div"
      color={'primary.dark'}
      sx={{
        textAlign: 'left',
      }}
    >
      {value}
    </Typography>
  );
};

export const getColumnData = (theme) => {
  const columnData = [];

  //add name of organisation
  columnData.push({
    Header: headerFormat('Mass [kg]'),
    headerStyle: {
      backgroundColor: theme.palette.primary.light,
      position: 'sticky',
      left: 0,
      zIndex: 5,
      minWidth: '7rem',
      paddingLeft: '0.75rem',
    },
    bodyStyle: {
      backgroundColor: 'white',
      position: 'sticky',
      left: 0,
      zIndex: 5,
      paddingTop: '0.75rem',
      paddingBottom: '0.75rem',
      paddingLeft: '0.75rem',
    },
    accessor: 'h2Available',

    Cell: ({ value }) => {
      return (
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'left',
          }}
        >
          {conversion(value, 'grToKg', 1)}
        </Typography>
      );
    },

    alignment: 'left',
  });

  //type time
  columnData.push({
    Header: headerFormat('Refuel time'),
    headerStyle: {
      backgroundColor: theme.palette.primary.light,
      minWidth: '7rem',
      paddingLeft: '0.75rem',
    },
    bodyStyle: {
      paddingLeft: '0.75rem',
    },
    accessor: 'time',
    Cell: ({ value }) => {
      const dateString = moment(value).local().format('DD-MM-YYYY HH:mm:ss');
      return (
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'left',
          }}
        >
          {dateString}
        </Typography>
      );
    },
    alignment: 'left',
  });

  //assigned units

  columnData.push({
    Header: headerFormat('H2 Total counter [kg]'),
    headerStyle: {
      backgroundColor: theme.palette.primary.light,
      minWidth: '7rem',
      maxWidth: '8rem',
      paddingLeft: '0.75rem',
    },
    bodyStyle: {
      paddingLeft: '0.75rem',
    },
    accessor: 'H2total',
    Cell: ({ value }) => {
      return (
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'left',
          }}
        >
          {value != null ? conversion(value, 'grToKg', 1) : ''}
        </Typography>
      );
    },
    alignment: 'left',
  });

  return columnData;
};
