import { useRouter } from 'next/router';
import fetchData from '@/utils/fetchData';
import postData from '@/utils/postData';
import { createContext, useEffect, useState } from 'react';
import { redirect } from 'next/navigation';
import Cookies from 'universal-cookie';
export const AuthContext = createContext({
  isLoggedIn: false,
  expiryTime: 0,
  userId: '',
  triggerLogin: false,
  setTriggerLogin: () => {},
  triggerLoginMessage: '',
  openLoginModal: false,
  setOpenLoginModal: () => {},
  formToShow: null,
  setFormToShow: () => {},
  onLogout: () => {},
  onLogoutClient: () => {},
  onLogin: () => {},
  setTokenExpiryTime: (time) => {},
  refresh: () => {},
  closeTriggerLogin: () => {},
  //fetching with authentication
  fetchDataContext: () => {},
  postDataContext: () => {},
  restrictTo: () => {},
  phoneVerified: false,
  organisation: '',
  acceptedTerms: true,
  setAcceptedTerms: () => {},
});

const AuthContextProvider = ({ children }) => {
  //States for authentication
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [expiryTime, setExpiryTime] = useState(Date.now() - 100);
  const [timerId, setTimerId] = useState(null);
  const [timerIdLogout, setTimerIdLogout] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [formToShow, setFormToShow] = useState(null);
  const [authenticationRole, setAuthenticationRole] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [organisation, setOrganisation] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const cookies = new Cookies();
  //states for programatic requesting user login
  //this is used when a user tries to reach a protected route without being logged in (e.g. expired token)
  const [triggerLogin, setTriggerLogin] = useState(false);
  const [triggerLoginMessage, setTriggerLoginMessage] = useState('');
  const router = useRouter();
  const onLogin = async (uId, expiry) => {
    /**
     * Sets the user ID, expiry time, and login status.
     * @async
     * @param {type} uId - The user ID.
     * @param {type} expiry - The expiry time. Time in milliseconds. Duration (i.e. Date.now() should be added)
     * @return {Promise<undefined>} This function does not return a value.
     */
    setUserId(uId);
    setExpiryTime(expiry + Date.now());
    setIsLoggedIn(true);
    //set cookie with expiry
    cookies.set('uId', uId, {
      path: '/',
      maxAge: expiry,
      sameSite: 'strict',
    });
    cookies.set('expiry', expiry + Date.now(), {
      path: '/',
      maxAge: expiry,
      sameSite: 'strict',
    });

    let [data, success, errorMessage] = await fetchData('/users/me', 'doc');
    if (success) {
      setAuthenticationRole(data.authenticationRole);
      setPhoneVerified(data.phoneNumberValidated || false);
      setOrganisation(data.organisation || '');
      setAcceptedTerms(data.acceptedTerms || false);
    }
  };

  const restrictTo = (role = [], redirectUrl = null) => {
    if (role.includes(authenticationRole)) {
      return true;
    } else {
      if (redirectUrl) {
        redirect(redirectUrl);
      }
      return false;
    }
  };

  const setTokenExpiryTime = (time) => {
    /**
     * Sets the expiry time of the token.
     *
     * @param {type} time - the time to set as the expiry time. This is a Date time
     * @return {type} undefined
     */
    setExpiryTime(time);
  };

  //send reminder to user to login 5 minutes prior to session end
  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (isLoggedIn && expiryTime > Date.now()) {
      const newTimerId = setTimeout(() => {
        setTriggerLoginMessage(
          'Your session is about to end in 5 minutes. Please log in again.'
        );
        setTriggerLogin(true);
        setFormToShow('login');
      }, expiryTime - Date.now() - 1 * 60 * 1000);
      setTimerId(newTimerId);
    }
  }, [isLoggedIn, expiryTime]);

  //send reminder on logout itself
  useEffect(() => {
    if (timerIdLogout) {
      clearTimeout(timerIdLogout);
    }
    if (isLoggedIn && expiryTime > Date.now()) {
      const newTimerId = setTimeout(() => {
        //user is not logged in anymore
        onLogoutClient();
        //request user to log in again
        setTriggerLoginMessage('Your session has ended. Please log in again.');
        setTriggerLogin(true);
        setFormToShow('login');
      }, expiryTime - Date.now() + 100);
      setTimerIdLogout(newTimerId);
    }
  }, [isLoggedIn, expiryTime]);

  //log out by resetting all states on the client side.
  //the token is however nog invalidated on the server side
  const onLogoutClient = () => {
    setIsLoggedIn(false);
    setExpiryTime(0);
    setUserId('');
    //remove cookie
    cookies.remove('uId', { path: '/' });
    cookies.remove('expiry', { path: '/' });
  };

  //log out on the server side and on the client side
  const onLogout = async () => {
    const [data, success, errorMessage] = await postData(
      null,
      false,
      '/users/logout'
    );
    onLogoutClient();
    router.reload();
  };

  //refresh token
  const refresh = async () => {
    //we cannot check if a refresh token is available as it is an httpOnly cookie, so we send request and pray
    if (isLoggedIn) {
      return;
    }
    //check if we have a cookie
    const uId = cookies.get('uId');
    if (uId) {
      //check if cookie is expired
      const expiryTime = parseInt(cookies.get('expiry'));
      const timeLeft = expiryTime - Date.now();
      if (timeLeft < 0) {
        onLogoutClient();
        return;
      }
      await onLogin(uId, timeLeft);

      // code to handle the case when the cookie exists
      return;
    } else {
      onLogoutClient();
    }

    // const [data, success, errorMessage] = await postData(
    //   null,
    //   false,
    //   "/users/refresh"
    // );
    // if (success) {
    //   onLogin(data.userId, data.expireTime);
    // } else {
    //   onLogoutClient();
    // }
  };

  //-----------------------------------------------------------------------------
  //    section below is more related to fetching and posting on protected routes
  //-----------------------------------------------------------------------------
  //function that runs after a unauthorized request was send (401)
  //this is used to trigger the display of the login modal
  const onUnauthorized = (message) => {
    setTriggerLogin(true);
    setTriggerLoginMessage(message);
  };

  //function to close the triggering
  const closeTriggerLogin = () => {
    setTriggerLogin(false);
    setTriggerLoginMessage('');
  };

  //fetching wrapper that includes logic for unauthorized requests
  const fetchDataContext = async (endpoint, parameter) => {
    const [data, success, errorMessage] = await fetchData(
      endpoint,
      parameter,
      onUnauthorized
    );
    return [data, success, errorMessage];
  };

  //posting wrapper that includes logic for unauthorized requests
  const postDataContext = async (data, update, endpoint) => {
    const [dataResponse, success, errorMessage] = await postData(
      data,
      update,
      endpoint,
      onUnauthorized
    );
    return [dataResponse, success, errorMessage];
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        expiryTime,
        userId,
        onLogin,
        onLogout,
        onLogoutClient,
        setTokenExpiryTime,
        refresh,
        fetchDataContext,
        postDataContext,
        closeTriggerLogin,
        triggerLogin,
        setTriggerLogin,
        triggerLoginMessage,
        openLoginModal,
        setOpenLoginModal,
        formToShow,
        setFormToShow,
        restrictTo,
        phoneVerified,
        organisation,
        acceptedTerms,
        setAcceptedTerms,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
