const fetchData = async (
  endpoint,
  parameter,
  onUnauthorized = null,
  method = 'GET'
) => {
  /**
   * Fetches data from the specified endpoint.
   *
   * @param {string} endpoint - The API endpoint to fetch data from.
   * @param {string} parameter - The optional parameter to filter the response data.
   * @return {[any, boolean, string]} - [data, success, message] An array containing the response data, a boolean indicating whether the request was successful, and an error message if any.
   */
  const url = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  //set options
  const requestOptions = {
    method,
    credentials: 'include',
  };

  //fetch
  let rawResponse;
  try {
    rawResponse = await fetch(url, requestOptions);
  } catch (error) {
    return [null, false, 'error while fetching data'];
  }

  if (rawResponse.status === 204) {
    return [undefined, true, undefined];
  }
  let response;
  //process output
  try {
    response = await rawResponse.json();
  } catch (error) {
    console.log(error);
    return [undefined, false, 'error while fetching data'];
  }

  //check if we did not get an unauthorized response
  if (rawResponse.status === 401) {
    let responseMessage = 'authorization error';
    if (response && response.message) {
      responseMessage = response.message;
    }
    if (onUnauthorized !== null) {
      onUnauthorized(responseMessage);
    }
    return [null, false, response.message];
  }

  if (parameter) {
    if (response && response.data && response.data[parameter]) {
      return [response.data[parameter], true, undefined];
    } else {
      // try {
      //   console.log('fetching error see response:', response);
      // } catch {
      //   console.log('unknown fetching error');
      // }
      return [undefined, false, 'error while fetching data'];
    }
  } else {
    if (response && (response.data || response.status === 'success')) {
      return [response.data, true, undefined];
    } else {
      // try {
      //   console.log('fetching error see response:', response);
      // } catch {
      //   console.log('unknown fetching error');
      // }
      return [undefined, false, 'error while fetching data'];
    }
  }
};

export default fetchData;
