import { useContext } from 'react';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardCustomization from './customization/CardCustomization';
import CardBox from './CardBox';
import useIsMobile from '@/hooks/useIsMobile';
import { DashboardContext } from '@/store/dashboard-context';
import TransparentLayer from './customization/TransparentLayer';
export default function EmptyCard(props) {
  const { editMode } = useContext(DashboardContext);
  const isMobile = useIsMobile('sm');
  const { item } = props;
  return (
    <CardBox height={1}>
      <Card sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            margin: 0,
            padding: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          }}
        >
          {editMode && (
            <>
              <CardCustomization item={item} />
            </>
          )}
        </Box>
        <CardContent>
          <TransparentLayer item={item} />
        </CardContent>
      </Card>
    </CardBox>
  );
}

export const emptyCardObj = (position, size) => {
  const obj = {
    type: 'EmptyCard',
    typeLabel: `Empty Card ${size[0] / 3}x${size[1]}`,
    content: 'Empty Card, change type to configure',
    position,
    size: size || [12, 1],
  };
  return obj;
};
