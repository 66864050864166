import NestedCard, { nestedCardObj } from '@/components/Dashboard/NestedCard';
import MapCard, { mapCardObj } from '@/components/Dashboard/MapCard';
import AlarmCard, { alarmCardObj } from '@/components/Dashboard/AlarmCard';
import ValueCard, { valueCardObj } from '@/components/Dashboard/ValueCard';
import FuelCard, { fuelCardObj } from '@/components/Dashboard/FuelCard';
import GraphCard, { graphCardObj } from '@/components/Dashboard/GraphCard';
import CommandCard, {
  commandCardObj,
} from '@/components/Dashboard/CommandCard';
import UsageCard, { usageCardObj } from '@/components/Dashboard/UsageCard';
import EmptyCard, { emptyCardObj } from '@/components/Dashboard/EmptyCard';
import NameCard, { nameCardObj } from '@/components/Dashboard/NameCard';
import OverviewCard, {
  overviewCardObj,
} from '@/components/Dashboard/OverviewCard';

//list of card options used to change the card type (in the card, for dashboard customization)
export const getCardOptions = (item) => {
  const cardOptions = {
    'Value Card 1x1': {
      typeLabel: 'Value Card 1x1',
      type: ['ValueCard'],
      size: [3, 1],
      inputs: [[item.position, [3, 1]]],
    },
    'Value Card 2x1': {
      typeLabel: 'Value Card 2x1',
      type: ['ValueCard'],
      size: [6, 1],
      inputs: [[item.position, [6, 1]]],
    },
    'Name Card 2x1': {
      typeLabel: 'Name Card 2x1',
      type: ['NameCard'],
      size: [6, 1],
      inputs: [[item.position, [6, 1]]],
    },
    'Fuel Card 2x1': {
      typeLabel: 'Fuel Card 2x1',
      type: ['FuelCard'],
      size: [6, 1],
      inputs: [[item.position, [6, 1]]],
    },
    'Fuel Card 4x1': {
      typeLabel: 'Fuel Card 4x1',
      type: ['FuelCard'],
      size: [12, 1],
      inputs: [[item.position, [12, 1]]],
    },
    'Usage Card 2x1': {
      typeLabel: 'Usage Card 2x1',
      type: ['UsageCard'],
      size: [6, 1],
      inputs: [[item.position, [6, 1]]],
    },
    'Graph Card 4x2': {
      typeLabel: 'Graph Card 4x2',
      size: [12, 2],
      type: ['GraphCard'],
      inputs: [[item.position, [12, 2]]],
    },
    'Alarm Card 2x1': {
      typeLabel: 'Alarm Card 2x1',
      size: [6, 1],
      type: ['AlarmCard'],
      inputs: [[item.position, [6, 1]]],
    },
    'Command Card 2x1': {
      typeLabel: 'Command Card 2x1',
      size: [6, 1],
      type: ['CommandCard'],
      inputs: [[item.position, [6, 1]]],
    },
    'Map Card 4x3': {
      typeLabel: 'Map Card 4x3',
      size: [12, 3],
      type: ['MapCard'],
      inputs: [[item.position, [12, 3]]],
    },
    'Map Card 4x2': {
      typeLabel: 'Map Card 4x2',
      size: [12, 2],
      type: ['MapCard'],
      inputs: [[item.position, [12, 2]]],
    },
    'Map Card 4x1': {
      typeLabel: 'Map Card 4x1',
      size: [12, 1],
      type: ['MapCard'],
      inputs: [[item.position, [12, 1]]],
    },
    'Empty Card 4x1': {
      typeLabel: 'Empty Card 4x1',
      size: [12, 1],
      type: ['EmptyCard'],
      inputs: [[item.position, [12, 1]]],
    },
    'Empty Card 3x1': {
      typeLabel: 'Empty Card 3x1',
      size: [9, 1],
      type: ['EmptyCard'],
      inputs: [[item.position, [9, 1]]],
    },
    'Empty Card 2x1': {
      typeLabel: 'Empty Card 2x1',
      size: [6, 1],
      type: ['EmptyCard'],
      inputs: [[item.position, [6, 1]]],
    },
    'Empty Card 1x1': {
      typeLabel: 'Empty Card 1x1',
      size: [3, 1],
      type: ['EmptyCard'],
      inputs: [[item.position, [3, 1]]],
    },
    'Overview Card 4x3': {
      typeLabel: 'Overview Card 4x3',
      size: [12, 3],
      type: ['OverviewCard'],
      inputs: [[item.position, [12, 3]]],
    },
  };
  return cardOptions;
};

//get card component based on card type, for rendering of the card from the dashboard
export const getElment = (type, item, nColumns) => {
  switch (type) {
    case 'EmptyCard':
      return <EmptyCard item={item} />;
    case 'NestedCard':
      return <NestedCard item={item} />;
    case 'MapCard':
      return <MapCard item={item} />;
    case 'AlarmCard':
      return <AlarmCard item={item} />;
    case 'ValueCard':
      return <ValueCard item={item} />;
    case 'FuelCard':
      return <FuelCard item={item} />;
    case 'UsageCard':
      return <UsageCard item={item} />;
    case 'GraphCard':
      return <GraphCard item={item} />;
    case 'CommandCard':
      return <CommandCard item={item} />;
    case 'NameCard':
      return <NameCard item={item} />;
    case 'OverviewCard':
      return <OverviewCard item={item} nColumns={nColumns} />;
    default:
      return <EmptyCard item={item} />;
  }
};

//get an empty card object, to be inserted into the dashboard object. Can be used when adding a new card or changing a card type
export const getCard = (type, args) => {
  switch (type) {
    case 'EmptyCard':
      return emptyCardObj(...args);
    case 'NestedCard':
      return nestedCardObj(...args);
    case 'MapCard':
      return mapCardObj(...args);
    case 'AlarmCard':
      return alarmCardObj(...args);
    case 'ValueCard':
      return valueCardObj(...args);
    case 'FuelCard':
      return fuelCardObj(...args);
    case 'UsageCard':
      return usageCardObj(...args);
    case 'CommandCard':
      return commandCardObj(...args);
    case 'GraphCard':
      return graphCardObj(...args);
    case 'NameCard':
      return nameCardObj(...args);
    case 'OverviewCard':
      return overviewCardObj(...args);
    default:
      return emptyCardObj(...args);
  }
};
