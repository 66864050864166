import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import LoginForm from './LoginForm';
import Account from './Account';
import RegisterForm from './RegisterForm';
import ForgotPasswordRequest from './ForgotPasswordRequest';
import ForgotPasswordReset from './ForgotPasswordReset';
import UpdatePassword from './UpdatePassword';
import VerifyEmail from './VerifyEmail';
import UpdateProfile from './UpdateProfile';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import { AuthContext } from '@/store/auth-context';
import VerifyInvite from './VerifyInvite';
import HandleQR from './HandleQR';
import ModalCloseButton from '@/components/Modals/utils/ModalCloseButton';

function Login(props) {
  const { isMobile = false } = props;
  const buttonSize = isMobile ? 'small' : 'large';
  const {
    isLoggedIn,
    refresh,
    openLoginModal,
    setOpenLoginModal,
    formToShow,
    setFormToShow,
    triggerLogin,
    closeTriggerLogin,
    expiryTime,
  } = useContext(AuthContext);

  //check for login triggers
  useEffect(() => {
    if (triggerLogin) {
      setOpenLoginModal(true);
    }
  }, [triggerLogin]);

  //refresh to login on page refresh / initial load
  useEffect(() => {
    const getAccessToken = async () => {
      await refresh();
      // closeTriggerLogin();
    };
    getAccessToken();
  }, []);

  const handleClose = () => {
    setOpenLoginModal(false);
    closeTriggerLogin();
    setFormToShow('login');
  };

  const formDisplay = (showingForm) => {
    if (showingForm === 'login' || !showingForm) {
      return <LoginForm handleClose={handleClose} />;
    }
    if (showingForm === 'register') {
      return <RegisterForm />;
    }
    if (showingForm === 'forgotPasswordRequest') {
      return <ForgotPasswordRequest />;
    }
    if (showingForm === 'forgotPasswordReset') {
      return <ForgotPasswordReset handleClose={handleClose} />;
    }
    if (showingForm === 'verifyEmail') {
      return (
        <VerifyEmail
          route="/users/verifyEmail"
          successMessage={
            'Welcome! You can now log in using your email and password.'
          }
        />
      );
    }
    if (showingForm === 'verifyEmailChange') {
      return (
        <VerifyEmail
          route="/users/verifyChangeEmail"
          successMessage="You can now log in using your new email and password."
        />
      );
    }
    if (showingForm === 'updateProfile') {
      return <UpdateProfile />;
    }
    if (showingForm === 'updatePassword') {
      return <UpdatePassword />;
    }
    if (showingForm === 'verifyInvite') {
      return <VerifyInvite />;
    }
    if (showingForm === 'qrCode') {
      return <HandleQR />;
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <Account />
      ) : (
        <Button
          color="secondary"
          variant="contained"
          size={buttonSize}
          sx={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
          onClick={() => {
            setOpenLoginModal(true);
          }}
        >
          Sign in
        </Button>
      )}
      <Modal
        open={openLoginModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflowY: 'scroll',
            maxHeight: '90%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '40rem' },
            p: { xs: '2rem', sm: '10rem' },
            bgcolor: 'background.paper',
          }}
        >
          <ModalCloseButton handleClose={handleClose} />
          {formDisplay(formToShow)}
        </Box>
      </Modal>
    </>
  );
}
export default Login;
