import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '@/store/auth-context';

import postData from '@/utils/postData';
function LoginForm(props) {
  //property that sets whether to show register or login
  const { handleClose, showButtonGroup = true } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  //state to hide and show password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //displaying messages beneeth form
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');

  //to apply color
  const theme = useTheme();

  //context
  const {
    onLogin,
    triggerLogin,
    triggerLoginMessage,
    closeTriggerLogin,
    openLoginModal,
    setFormToShow,
  } = useContext(AuthContext);

  //check for login triggers. This is trigger is basically a programatic request to login again.
  useEffect(() => {
    if (triggerLogin && triggerLoginMessage) {
      setOpenAlert(true);
      setMessage(triggerLoginMessage);
    }
  }, [triggerLogin, triggerLoginMessage]);

  //when the modal closes, we must make sure that we clear the messages and also the filled in fields.
  //we don't want those to be become public.
  useEffect(() => {
    if (!openLoginModal) {
      setOpenAlert(false);
      setMessage('');
      setEmail('');
      setPassword('');
    }
  }, [openLoginModal]);

  //submit form to backend for login
  //check for email and password
  //message on error and close on succes
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email.trim(),
      password: password.trim(),
    };

    if (email.length === 0) {
      setOpenAlert(true);
      setMessage('Please enter email');
      return;
    } else if (password.length === 0) {
      setOpenAlert(true);
      setMessage('Please enter password');
      return;
    }

    const [loginData, success, postMessage] = await postData(
      data,
      false,
      '/users/login'
    );
    if (!success) {
      setOpenAlert(true);
      setMessage(postMessage);
    } else {
      closeTriggerLogin();
      await onLogin(loginData.userId, loginData.expireTime);
      setMessage('');
      setOpenAlert(false);
      handleClose();
    }
  };

  return (
    <>
      <FormGroup>
        {showButtonGroup && (
          <ButtonGroup sx={{ mt: 1, width: '100%' }}>
            <Button
              color="primary"
              key={'register-button'}
              variant="outlined"
              sx={{
                width: '50%',
              }}
              onClick={() => setFormToShow('register')}
            >
              Register
            </Button>
            <Button
              color="primary"
              variant="outlined"
              key={'login-button'}
              disabled
              sx={{
                '&.Mui-disabled': {
                  background: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                },
                width: '50%',
              }}
            >
              Login
            </Button>
          </ButtonGroup>
        )}

        <TextField
          id="email-field"
          label="Email"
          variant="outlined"
          sx={{ mt: 1 }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="pasword-field"
          label="Password"
          variant="outlined"
          sx={{ mt: 1 }}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="text"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={() => {
            setFormToShow('forgotPasswordRequest');
          }}
        >
          Forgot password
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ mt: 1, width: '100%' }}
          onSubmit={handleSubmit}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {openAlert && <Alert severity="error">{message}</Alert>}
      </FormGroup>
    </>
  );
}
export default LoginForm;
